import {
  searchRooms,
  getNewRoomsCnt,
  addRoom,
  setRoomSuspended,
  getRoom,
  setRoomSaved,
} from './thunks';

const thunksConfig = [
  {
    type: 'searchRooms',
    thunk: searchRooms,
    callbacks: {
      fulfilled: (state, action) => {
        state.rooms = action.payload.data;
        state.cnt = action.payload.cnt;
        state.newCnt = action.payload.newCnt;
        if (action.payload.checkPagination) {
          state.currentPage = 1;
        }
      },
    },
  },
  {
    type: 'getNewRoomsCnt',
    thunk: getNewRoomsCnt,
    callbacks: {
      fulfilled: (state, action) => {
        state.newCnt = action.payload.cnt;
      },
    },
  },
  {
    type: 'addRoom',
    thunk: addRoom,
  },
  {
    type: 'setRoomSuspended',
    thunk: setRoomSuspended,
  },
  {
    type: 'getRoom',
    thunk: getRoom,
    callbacks: {
      fulfilled: (state, action) => {
        state.selectedRoom = action.payload.success;
      },
    },
  },
  {
    type: 'setRoomSaved',
    thunk: setRoomSaved,
    callbacks: {
      fulfilled: (state, action) => {
        if (action.payload.isOnlySingle) {
          state.selectedRoom = action.payload.selectedRoom;
        } else {
          state.rooms = action.payload.rooms;
        }
      },
    },
  },
];

export default thunksConfig;
