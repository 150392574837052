import { withLazyLoad } from '@hocs';
import { PATHS } from './paths';
import { ROUTE_SCOPES } from './scopes';

const { AUTH, NO_AUTH } = ROUTE_SCOPES;

const NotFoundScreen = withLazyLoad(() => import('@screens/Auth/NotFound'));
const SignInScreen = withLazyLoad(() => import('@screens/NoAuth/SignIn'));
const SignUpScreen = withLazyLoad(() => import('@screens/NoAuth/SignUp'));
const HomeScreen = withLazyLoad(() => import('@screens/Auth/Home'));
const ResidentStep1Screen = withLazyLoad(() => import('@screens/NoAuth/ResidentStep1'));
const ResidentStep2Screen = withLazyLoad(() => import('@screens/NoAuth/ResidentStep2'));
const BusinessStep1Screen = withLazyLoad(() => import('@screens/NoAuth/BusinessStep1'));
const BusinessStep2Screen = withLazyLoad(() => import('@screens/NoAuth/BusinessStep2'));
const BusinessStep3Screen = withLazyLoad(() => import('@screens/NoAuth/BusinessStep3'));
const ResidentMessageScreen = withLazyLoad(() => import('@screens/NoAuth/ResidentMessage'));
const BusinessMessageScreen = withLazyLoad(() => import('@screens/NoAuth/BusinessMessage'));
const PassRecoveryScreen = withLazyLoad(() => import('@screens/NoAuth/PassRecover'));
const FindAccountScreen = withLazyLoad(() => import('@screens/NoAuth/FindAccount'));
const ActiveScreen = withLazyLoad(() => import('@screens/NoAuth/Activate'));
const AcceptedProjectsScreen = withLazyLoad(() => import('@screens/Auth/AcceptedProjects'));
const MemberProjectsScreen = withLazyLoad(() => import('@screens/Auth/MemberProjects'));
const SendMessageScreen = withLazyLoad(() => import('@screens/Auth/SendMessage'));
const SendMessageResidentScreen = withLazyLoad(() => import('@screens/Auth/SendMessageResident'));
const ApplicationsScreen = withLazyLoad(() => import('@screens/Auth/Applications'));
const ResidentsScreen = withLazyLoad(() => import('@screens/Auth/Residents'));
const MessagesListScreen = withLazyLoad(() => import('@screens/Auth/MessagesList'));
const MessageInnerScreen = withLazyLoad(() => import('@screens/Auth/MessageInner'));
const RoomScreen = withLazyLoad(() => import('@screens/Auth/Room'));
const NotifyListScreen = withLazyLoad(() => import('@screens/Auth/NotifyList'));
const NotifyInnerScreen = withLazyLoad(() => import('@screens/Auth/NotifyInner'));
const SendNotifyScreen = withLazyLoad(() => import('@screens/Auth/SendNotify'));
const ChangePassScreen = withLazyLoad(() => import('@screens/Auth/ChangePass'));
const EditInfoScreen = withLazyLoad(() => import('@screens/Auth/EditInfo'));
const ProjectInnerScreen = withLazyLoad(() => import('@screens/Auth/ProjectInner'));
const MemberProjectInnerScreen = withLazyLoad(() => import('@screens/Auth/MemberProjectInner'));

export const ROUTES = [
  {
    exact: true,
    path: PATHS.SIGN_IN(),
    component: SignInScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.SIGN_UP(),
    component: SignUpScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.HOME(),
    component: HomeScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.RESIDENT_STEP_1(),
    component: ResidentStep1Screen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.RESIDENT_STEP_2(),
    component: ResidentStep2Screen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.BUSINESS_STEP_1(),
    component: BusinessStep1Screen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.BUSINESS_STEP_2(),
    component: BusinessStep2Screen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.BUSINESS_STEP_3(),
    component: BusinessStep3Screen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.RESIDENT_MESSAGE(),
    component: ResidentMessageScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.ACTIVATE(),
    component: ActiveScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.BUSINESS_MESSAGE(),
    component: BusinessMessageScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.PASS_RECOVER(),
    component: PassRecoveryScreen,
    scope: NO_AUTH,
  },
  {
    exact: true,
    path: PATHS.FIND_ACCOUNT(),
    component: FindAccountScreen,
    scope: NO_AUTH,
  },
  // {
  //   exact: true,
  //   path: PATHS.ACCEPTED_PROJECTS(),
  //   component: AcceptedProjectsScreen,
  //   scope: AUTH,
  // },
  {
    exact: true,
    path: PATHS.MEMBER_PROJECTS(),
    component: MemberProjectsScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.SEND_MESSAGE(),
    component: SendMessageScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.SEND_MESSAGE_RESIDENT(),
    component: SendMessageResidentScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.APPLICATIONS(),
    component: ApplicationsScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.RESIDENTS(),
    component: ResidentsScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.MESSAGES_LIST(),
    component: MessagesListScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.MESSAGE_INNER(),
    component: MessageInnerScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.ROOM(),
    component: RoomScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.NOTIFY_LIST(),
    component: NotifyListScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.NOTIFY_INNER(),
    component: NotifyInnerScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.SEND_NOTIFY(),
    component: SendNotifyScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.CHANGE_PASS(),
    component: ChangePassScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.EDIT_INFO(),
    component: EditInfoScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.PROJECT_INNER(),
    component: ProjectInnerScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.MEMBER_PROJECT_INNER(),
    component: MemberProjectInnerScreen,
    scope: AUTH,
  },
  {
    exact: true,
    path: PATHS.NOT_FOUND(),
    component: NotFoundScreen,
    scope: AUTH,
  },
];
