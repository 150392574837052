import {
  searchOpportunities,
  getNewOpportunitiesCnt,
  addOpportunity,
  approveOpportunity,
  declineOpportunity,
  getOpportunity,
  setOpportunitySaved,
} from './thunks';

const thunksConfig = [
  {
    type: 'searchOpportunities',
    thunk: searchOpportunities,
    callbacks: {
      fulfilled: (state, action) => {
        state.opportunities = action.payload.data;
        state.cnt = action.payload.cnt;
        state.newCnt = action.payload.newCnt;
        if (action.payload.checkPagination) {
          state.currentPage = 1;
        }
      },
    },
  },
  {
    type: 'getNewOpportunitiesCnt',
    thunk: getNewOpportunitiesCnt,
    callbacks: {
      fulfilled: (state, action) => {
        state.newCnt = action.payload.cnt;
      },
    },
  },
  {
    type: 'getOpportunity',
    thunk: getOpportunity,
    callbacks: {
      fulfilled: (state, action) => {
        state.selectedOpportunity = action.payload.success;
      },
    },
  },
  {
    type: 'approveOpportunity',
    thunk: approveOpportunity,
  },
  {
    type: 'declineOpportunity',
    thunk: declineOpportunity,
  },
  {
    type: 'addOpportunity',
    thunk: addOpportunity,
  },
  {
    type: 'setOpportunitySaved',
    thunk: setOpportunitySaved,
    callbacks: {
      fulfilled: (state, action) => {
        if (action.payload.isOnlySingle) {
          state.selectedOpportunity = action.payload.selectedOpportunity;
        } else {
          state.opportunities = action.payload.opportunities;
        }
      },
    },
  },
];

export default thunksConfig;
