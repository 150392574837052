const en = {
  signIn: 'Login',
  signUp: 'Sign up',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  registerAs: 'Login as a',
  registration: 'Registration',
  resident: 'Member',
  business: 'Business',
  personalInformation: 'Personal data',
  signInInfo: 'Login data',
  picture: 'Photo',
  name: 'Name',
  nameArm: 'Name (in Armenian)',
  nameAnsi: 'Name (in English)',
  surname: 'Surname',
  surnameArm: 'Surname (in Armenian)',
  surnameAnsi: 'Surname (in English)',
  country: 'Country',
  companyCountry: 'Country(s) of activity',
  save: 'Save',
  companyName: 'Company name',
  companyNameAnsi: 'Company name (in English)',
  companyNameInfo: 'Please fill without mentioning entity type',
  companyRegistrationAddress: 'Company head office registration country',
  companyTIN: 'TIN',
  phone: 'Contact person phone number',
  investorPhone: 'Phone number',
  onlyAdminAccess: 'Is only available to the platform moderator',
  segment: 'Company field(s) of activity',
  continue: 'Next',
  repeatPassword: 'Reenter the password ',
  previous: 'Back',
  confirm: 'Submit',
  thankYou: 'Thank you',
  toWebsite: 'Back to home',
  passwordRecover: 'Reset password',
  send: 'Send',
  newPassword: 'New password',
  oldPassword: 'Old password',
  confirmedProjects: 'Investment projects',
  memberProjects: 'Member companies',
  message: 'Text',
  messages: 'Messages',
  residents: 'Members',
  community: 'Community',
  editInformation: 'Edit personal data',
  changePassword: 'Change password',
  logOut: 'Exit',
  favouriteProjects: 'Favorite projects',
  favouriteMessages: 'Favorite messages',
  date: 'Date',
  nameSurname: 'Name Surname',
  projectName: 'Project name',
  title: 'Subject',
  attachedFile: 'Attached file',
  structure: 'Structure/Body',
  filterAs: 'Filters',
  residentStatus: 'User status',
  upload: 'Upload',
  passport: 'Passport',
  attachDocument: 'Attach a document',
  companyRegisterDocument: 'Company registration certificate',
  companyInfo: 'Company data',
  pendingApplication: 'Your application is under review',
  messageToResident: 'Address members',
  receiverResidentSegments: 'Recipients sphere of activity',
  messageTitle: 'Letter subject',
  writeMessage: 'Body',
  attachProgram: 'Attach a file',
  accept: 'Apply',
  requests: 'Applications',
  notificationToResident: 'Notification to member',
  confirmationStep: 'Under consideration',
  decline: 'Decline',
  declined: 'Declined',
  modified: 'Updated',
  receiver: 'Recipients',
  sendNewNotification: 'New notification',
  roles: {
    seeker: 'Business',
    investor: 'Member',
    admin: 'Super admin',
  },
  projectOrCompanyName: 'Name of project/company',
  activationSuccess: 'Your account is successfully created.',
  invalidMailOrPassword: 'Invalid email/password',
  invalidMail: 'Email address must be in the following format: exаmple@exаmple.com',
  invalidPassword: 'Invalid password',
  invalidPhone: 'Invalid phone number',
  perPage: 'per page',
  sendTo: 'To / fields',
  sendToSingle: 'To',
  messageSentSuccessfully: 'Email successfully sent',
  messageSentToChosenSectors: (mail) => `Your application has been sent to revision. You will receive a letter on results to ${mail} within 5 working days.`,
  sendNewMessage: 'Send new email',
  blockedSeekerNotifier: (name) => `You will no longer receive letters from ${name}.`,
  blockUser: 'Block user',
  block: 'Block',
  notifications: 'Notifications',
  savedChanges: 'Your changes saved',
  notFilled: 'Mandatory field',
  oldAndNewPasswordsMatch: 'The new password matches the old one',
  notMatchedPassword: "Password doesn't match",
  passChangedSuccess: 'Password successfully changed',
  rejected: 'Declined',
  approved: 'Approved',
  unverified: 'Unverified',
  declineMessage: 'Decline Letter',
  approveMessage: 'Approve Letter',
  deleteResident: 'Delete the member',
  deleteResidentNotifier: "You are sure that you want to delete the member's page",
  delete: 'Delete',
  documentTitle: 'Business Platform for new business cooperations',
  documentDescription: 'A closed business platform uniting Armenian businessmen from all over the'
    + 'world. Networking & collaboration with businessmen from various fields of economy. Checked investment packages.',
  attachFile: 'Attach file',
  writeNewMessage: 'New letter',
  emailExists: 'Email already exist',
  emailDoesNotExist: 'Email not found',
  phoneExists: 'Phone number is already registered',
  residentRegisterSuccess: () => 'To complete your registration, please follow the link sent to your email address.',
  businessRegisterSuccess: 'You are successfully registered in the platform',
  passwordRecoverSuccess: (email) => `Password reset link has been sent to your email at <span class='helvetica-85' >${email}</span>`,
  noData: 'No data',
  noDataResidentsMsg: 'Your correspondence with community members will be reflected here.',
  fullName: 'Full name',
  field: 'Field',
  fields: 'Fields',
  byName: 'Name',
  byStatus: 'Status',
  byField: 'Field',
  state: 'Status',
  fillNameSurname: 'Fill Name Surname',
  invalidActivationCode: 'Invalid activation code',
  aboutProject: 'About project',
  participant: 'Participant',
  participants: 'Participants',
  searchPlaceholder: 'Search',
  all: 'All',
  searchResidentBy: 'Search members by',
  expert: 'Expert',
  notificationsReceived: (investorsCount) => `Recipients - ${investorsCount}`,
  download: 'Download',
  noApprovedResident: 'You will gain full access to the platform once your application is confirmed. Your response will be provided within 5 business days.',
  reply: 'Reply',
  fromResident: 'From businessmen',
  sender: 'Sender',
  fromBusiness: 'Other requests',
  companySize: 'Business Size',
  companySizeInfo: 'Based on issued invoices',
  communicationLanguage: 'Communication language(s)',
  birth: 'Date of birth',
  unBlock: 'Unblock',
  unBlockUser: 'Unblock the user',
  suspendRoom: 'Delete Message',
  unBlockedSeekerNotifier: (name) => `Do you want to unblock ${name}?`,
  maxItems: (number) => `Up to ${number} options.`,
  firstNameInvalidLanguage: 'Name must be in the required language',
  lastNameInvalidLanguage: 'Surname must be in the required language',
  companyInvalidLanguage: 'Company name must be in the required language',
  brandInvalidLanguage: 'Brands name must be in the required language',
  invalidTIN: 'Invalid TIN',
  position: 'Your current position at the company',
  positionSingle: 'Position',
  positionInfo: 'Up to two options/ if you don\'t find the exact name of your position, specify the closest one in the hierarchy',
  type: 'type',
  sendMessage: 'Send message',
  allProjects: 'All projects',
  investmentObjective: 'Investment objective',
  investmentRound: 'Investment raund',
  investmentRoundInfo: '*For startups only (pre-seed, seed, Round A etc.)',
  forecastIndicators: 'Forecast indicators',
  requiredInvestmentVolume: 'Required investment volume',
  profitability: 'Profitability/EBITDA',
  profitabilityInfo: `*EBITDA margin is an indicator of the company's operational profitability,
  calculated as follows. EBITDA = Revenue - Expenses (excluding interest, taxes, depreciation
  and amortization). EBITDA margin = (EBITDA / Revenue) * 100`,
  averageRevenue: 'Average annual revenue',
  averageRevenueInfo: '*Average annual revenue is calculated for the period up to the break even point',
  breakEventPoint: 'Break even point',
  contactFounder: 'Contact founder',
  projectDescription: 'Brief description of the project',
  aboutCompany: 'About company',
  cooperationFormats: 'Collaboration format',
  employeeNumber: 'Number of employees',
  founded: 'Establishment date',
  website: 'Website',
  contactCompany: 'Contact Company',
  subSegment: 'Subfield(s) of activity',
  favouriteResidents: 'Saved member',
  brand: 'Brands',
  brandAnsi: 'Brands (in English)',
  invalidUrl: 'Invalid url',
  favouritePartners: 'Saved companies',
  countryCode: 'Code',
  linkedUser: 'Company linked user',
  backToCommunity: 'Back to community',
};

export default en;
