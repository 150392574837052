import AuthRouters from '@routers/AuthRouters';
import NoAuthRouters from '@routers/NoAuthRouters';
import { Route as CommonWrapper } from 'react-router-dom';

export const ROUTE_SCOPES = {
  AUTH: 'auth',
  NO_AUTH: 'no_auth',
  COMMON: 'common',
};

export const ROUTE_SCOPE_WRAPPERS = {
  [ROUTE_SCOPES.AUTH]: AuthRouters,
  [ROUTE_SCOPES.NO_AUTH]: NoAuthRouters,
  [ROUTE_SCOPES.COMMON]: CommonWrapper,
};

export const getScopeWrapper = (scope = ROUTE_SCOPES.COMMON) => ROUTE_SCOPE_WRAPPERS[scope];
