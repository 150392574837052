import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';
import { ROLES, SLICE_NAMES } from '@constants';
import { getRole } from '@utils';

export const searchBroadcasts = createAsyncThunk(
  'broadcast/searchBroadcasts',
  async ({ offset, type = 'table' }, { getState }) => {
    const role = getRole();
    const pageSize = getState()[SLICE_NAMES.BROADCAST].pageSize;
    const keyword = getState()[SLICE_NAMES.INIT].keywordFilter;
    const cnt = getState()[SLICE_NAMES.BROADCAST].cnt;

    let current = offset * pageSize || 0;
    const countResponse = await request({ method: 'get', url: `/private/${role}/searchBroadcasts/0/0?cnt`, params: { keyword } });

    if (countResponse.data) {
      const checkPagination = Math.ceil(countResponse.data / pageSize) !== Math.ceil(cnt / pageSize);
      if (checkPagination) current = 0;

      const broadCastsResponse = await request({ method: 'get', url: `/private/${role}/searchBroadcasts/${current}/${pageSize}`, params: { keyword } });
      return {
        type,
        cnt: countResponse.data,
        broadcasts: broadCastsResponse.data,
        checkPagination,
      };
    }

    return { type, cnt: 0, broadcasts: [] };
  },
);

export const getBroadcast = createAsyncThunk(
  'broadcast/getBroadcast',
  async (id) => {
    const role = getRole();
    const broadcastResponse = await request({ method: 'get', url: `/private/${role}/getBroadcast/${id}` });
    return broadcastResponse.data.success;
  },
);

export const addBroadcast = createAsyncThunk(
  'broadcast/addBroadcast',
  async (data) => {
    await request({ method: 'post', url: '/private/admin/addBroadcast', data });
  },
);

export const getNotReadBroadcastsCount = createAsyncThunk(
  'broadcast/getNotReadBroadcastsCount',
  async () => {
    if (getRole() === ROLES.INVESTOR) {
      const notReadCountResponse = await request({
        method: 'get',
        url: '/private/investor/searchBroadcasts/0/0',
        params: { cnt: '', new: '' },
      });

      return notReadCountResponse.data;
    }

    return 0;
  },
);
