import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';
import { SLICE_NAMES } from '@constants';
import { setBookmarkProperty } from '@utils';

export const searchProjects = createAsyncThunk(
  'project/searchProjects',
  async ({ offset, bookmarked }, { getState }) => {
    const pageSize = getState()[SLICE_NAMES.PROJECT].pageSize;
    let current = offset * pageSize || 0;
    const cnt = getState()[SLICE_NAMES.PROJECT].cnt;
    const keyword = getState()[SLICE_NAMES.INIT].keywordFilter;
    const filters = getState().project.filters;
    const bookmarkedQuery = {};
    if (bookmarked) {
      bookmarkedQuery.bookmarked = '';
    }

    const totalProjectsRes = await request({
      method: 'get',
      url: '/private/investor/searchProjects/0/0?cnt',
      params: {
        cnt: '',
        ...bookmarkedQuery,
        keyword,
      },
    });

    let totalProjects = null;
    if (totalProjectsRes.data) {
      totalProjects = totalProjectsRes.data;
    }

    const projectsCountResponse = await request({
      method: 'get',
      url: '/private/investor/searchProjects/0/0',
      params: {
        cnt: '',
        ...bookmarkedQuery,
        keyword,
        ...filters,
      },
    });

    if (projectsCountResponse.data) {
      const checkPagination = Math.ceil(projectsCountResponse.data / pageSize) !== Math.ceil(cnt / pageSize);
      if (checkPagination) current = 0;

      const projectsResponse = await request({
        method: 'get',
        url: `/private/investor/searchProjects/${current}/${pageSize}`,
        params: {
          ...bookmarkedQuery,
          keyword,
          ...filters,
        },
      });

      if (projectsResponse.data && Array.isArray(projectsResponse.data)) {
        return {
          bookmarked,
          cnt: projectsCountResponse.data,
          projects: projectsResponse.data,
          checkPagination,
          totalProjects,
        };
      }
    }

    return {
      cnt: 0,
      projects: [],
      bookmarked,
      totalProjects,
    };
  },
);

export const setBookmarked = createAsyncThunk(
  'project/setBookmarked',
  async ({ data, isOnlySingle = false }, { getState }) => {
    const projects = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.PROJECT].projects));
    const currentProject = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.PROJECT].currentProject));
    const setBookmarkedResponse = await request({ method: 'post', url: '/private/investor/setProjectBookmarked', data });

    if (setBookmarkedResponse.data.success) {
      if (isOnlySingle) {
        currentProject.bookmarked = data.bookmarked;
      } else {
        projects.forEach((project) => {
          if (project.id === data.project) {
            project.bookmarked = data.bookmarked;
          }
        });
      }
    }

    return { isOnlySingle, projects, currentProject };
  },
);

export const getProject = createAsyncThunk(
  'project/getProject',
  async (id) => {
    const getProjectResponse = await request({ method: 'get', url: `/private/investor/getProject/${id}` });
    return getProjectResponse.data.success;
  },
);
