const ru = {
  signIn: 'Войти',
  signUp: 'Регистрация',
  email: 'Эл.почта',
  password: 'Пароль',
  forgotPassword: 'Забыли пароль?',
  registerAs: 'Регистрация как',
  registration: 'Регистрация',
  resident: 'Пользователь',
  business: 'Бизнес',
  personalInformation: 'Личные данные',
  signInInfo: 'Параметры входа',
  picture: 'Фото',
  name: 'Имя',
  nameArm: 'Имя (на армянском)',
  nameAnsi: 'Name (латиницей)',
  surname: 'Фамилия',
  surnameArm: 'Фамилия (на армянском)',
  surnameAnsi: 'Surname (латиницей)',
  country: 'Страна',
  companyCountry: 'Страна(ы) деятельности',
  save: 'Сохранить',
  companyName: 'Название компании',
  companyNameAnsi: 'Название компании (латиницей)',
  companyNameInfo: 'Просим заполнить без указания юридического лица',
  companyRegistrationAddress: 'Страна регистрации головного офиса компании',
  companyTIN: 'ИНН',
  phone: 'Номер телефона контактного лица',
  investorPhone: 'Номер телефона',
  onlyAdminAccess: 'Доступен только модератору платформы',
  segment: 'Сфера(ы) деятельности',
  continue: 'Далее',
  repeatPassword: 'Повторить пароль',
  previous: 'Назад',
  confirm: 'Подтвердить',
  thankYou: 'Спасибо',
  toWebsite: 'Вернуться на главную',
  passwordRecover: 'Восстановить пароль',
  send: 'Отправить',
  newPassword: 'Новый пароль',
  oldPassword: 'Старый пароль',
  confirmedProjects: 'Инвестиционные проекты',
  memberProjects: 'Компании члены',
  message: 'Текст',
  messages: 'Письма',
  residents: 'Пользователи',
  community: 'Сообщество',
  editInformation: 'Редактировать персональные данные',
  changePassword: 'Изменить пароль',
  logOut: 'Выход',
  favouriteProjects: 'Отмеченные проекты',
  favouriteMessages: 'Отмеченные сообшения',
  date: 'Дата',
  nameSurname: 'Имя фамилия',
  projectName: 'Название проекта',
  title: 'Тема',
  attachedFile: 'Прикрепленный файл',
  structure: 'Структура',
  filterAs: 'Фильтры',
  residentStatus: 'Статус пользователя',
  upload: 'Загрузить',
  passport: 'Паспорт',
  attachDocument: 'Прикрепить документ',
  companyRegisterDocument: 'Свидетельство о регистрации компании',
  companyInfo: 'Данные компании',
  pendingApplication: 'Ваша заявка в процессе рассмотрения',
  messageToResident: 'Письмо пользователям',
  receiverResidentSegments: 'Сфера деятельности получателей',
  messageTitle: 'Тема письма',
  writeMessage: 'Содержание письма',
  attachProgram: 'Прикрепить документ',
  accept: 'Применить',
  requests: 'Заявки',
  notificationToResident: 'Уведомление пользователю',
  confirmationStep: 'На рассмотрении',
  decline: 'Отклонить',
  declined: 'Отклоненные',
  modified: 'Обновленный',
  receiver: 'Получатели',
  sendNewNotification: 'Новое оповещение',
  roles: {
    seeker: 'Бизнес',
    investor: 'Пользователь',
    admin: 'Админ',
  },
  projectOrCompanyName: 'Название проекта/компании',
  activationSuccess: 'Ваш аккаунт успешно активирован.',
  invalidMailOrPassword: 'Неверный адрес электронной почты/пароль',
  invalidMail: 'Адрес электронной почты должен быть в следующем формате: exаmple@exаmple.com',
  invalidPassword: 'Неверный пароль',
  invalidPhone: 'Неправильный номер телефона',
  perPage: 'Показать на странице',
  sendTo: 'Кому / сферы',
  sendToSingle: 'Кому',
  messageSentSuccessfully: 'Письмо успешно отправлено',
  messageSentToChosenSectors: (mail) => `Ваше письмо отправлено на проверку. Результат рассмотрения будет отправлен на почту ${mail} в течение 5 рабочих дней.`,
  sendNewMessage: 'Отправить новое письмо',
  blockedSeekerNotifier: (name) => `Вы больше не будете получать письма от ползователя "${name}".`,
  blockUser: 'Заблокировать пользователя',
  block: 'Заблокировать',
  notifications: 'Оповещения',
  savedChanges: 'Ваши изменения сохранены',
  notFilled: 'Обязательное поле',
  oldAndNewPasswordsMatch: 'Новый пароль совпадает со старым',
  notMatchedPassword: 'Пароль не совпадает',
  passChangedSuccess: 'Пароль успешно изменен',
  rejected: 'Откланенные',
  approved: 'Одобренные',
  unverified: 'Нерассмотренные',
  declineMessage: 'Отклонить Письмo',
  approveMessage: 'Принять Письмo',
  deleteResident: 'Удалить пользователя',
  deleteResidentNotifier: 'Вы уверены, что хотите удалить страницу пользователя',
  delete: 'Удалить',
  documentTitle: 'Бизнес-платформа для новых деловых партнерств',
  documentDescription: 'Закрытая бизнес-площадка, объединяющая армянских бизнесменов со всего мира.'
    + 'Нетворкинг и сотрудничество с бизнесменами из разных областей экономики. Проверенные инвестиционные пакеты.',
  attachFile: 'прикрепить файл',
  writeNewMessage: 'Новое письмо',
  emailExists: 'Эл.почта существует',
  emailDoesNotExist: 'Адрес эл.почты не найден',
  phoneExists: 'Номер телефона уже зарегистрирован',
  residentRegisterSuccess: () => 'Для завершения регистрации перейдите по ссылке, отправленной на ваш адрес электронной почты.',
  businessRegisterSuccess: 'Вы успешно зарегистрированы на платформе',
  passwordRecoverSuccess: (email) => `Ссылка для сброса пароля отправлена на ваш адрес электронной почты <span class='helvetica-85' >${email}</span>`,
  noData: 'Данные отсутствуют',
  noDataResidentsMsg: 'Здесь будет отражаться Ваша переписка с сообществом.',
  fullName: 'Полное название',
  field: 'Сфера',
  fields: 'Сферы',
  byName: 'Названию',
  byStatus: 'Статусу',
  byField: 'Сфере',
  state: 'Статус',
  fillNameSurname: 'Заполнить Имя Фамилию',
  invalidActivationCode: 'Неверный код активации',
  aboutProject: 'О проекте',
  participant: 'Член',
  participants: 'Члены',
  searchPlaceholder: 'Поиск',
  all: 'Все',
  searchResidentBy: 'Поиск пользователей по',
  expert: 'Эксперт',
  notificationsReceived: (investorsCount) => `Получатели - ${investorsCount}`,
  download: 'Скачать',
  noApprovedResident: 'Вам откроется полный доступ к платформе после подтверждения Вашей заявки. Вы получите ответ в течение 5 рабочих дней.',
  reply: 'Ответить',
  fromResident: 'От предпринимателей',
  sender: 'Отправитель',
  fromBusiness: 'Другие запросы',
  companySize: 'Размер бизнеса',
  companySizeInfo: 'По выписанным счетам',
  communicationLanguage: 'Язык(и) коммуниации',
  birth: 'Дата рождения',
  unBlock: 'Разблокировать',
  unBlockUser: 'Разблокировать пользователя',
  suspendRoom: 'Удалить сообщение',
  unBlockedSeekerNotifier: (name) => `Хотите разблокировать пользователя ${name}?`,
  maxItems: (number) => `До ${number} вариантов.`,
  firstNameInvalidLanguage: 'Имя должно быть подходящим языком',
  lastNameInvalidLanguage: 'Фамилия должно быть подходящим языком',
  companyInvalidLanguage: 'Название компании должно быть подходящим языком',
  brandInvalidLanguage: 'Название брендов должно быть подходящим языком',
  invalidTIN: 'Недействительный ИНН',
  position: 'Ваша текущая должность',
  positionSingle: 'Должность',
  positionInfo: 'До двух вариантов/ если Вы не нашли точное наименование Вашей должности укажите наиболее близкую по иерархии',
  type: 'тип',
  sendMessage: 'Написать письмо',
  allProjects: 'Все проекты',
  investmentObjective: 'Цель инвестиций',
  investmentRound: 'Раунд инвестиций',
  investmentRoundInfo: '*Для стратапов (pre-seed, seed, Round A и т.д.)',
  forecastIndicators: 'Прогнозируемые показатели',
  requiredInvestmentVolume: 'Требуемый объем инвестиций',
  profitability: 'Прибыльность/EBITDA',
  profitabilityInfo: `*EBITDA margin - это показатель операционной рентабельности
  компаниис учетом доли инвестора, рассчитываемый следующим образом: EBITDA = Выручка -
  Расходы (за исключением процентов, налогов, износа и амортизации). EBITDA margin = (EBITDA / Выручка) * 100`,
  averageRevenue: 'Среднегодовая выручка ',
  averageRevenueInfo: '*Среднегодовая выручка рассчитывается для периода до срока окупаемости',
  breakEventPoint: 'Срок окупаемости',
  contactFounder: 'Связь с основателем',
  projectDescription: 'Краткое описание проекта',
  aboutCompany: 'О компании',
  cooperationFormats: 'Формат сотрудничества',
  employeeNumber: 'Количество сотрудников',
  founded: 'Дата учреждения',
  website: 'Сайт',
  contactCompany: 'Связаться с компанией',
  subSegment: 'Направления деятельности',
  favouriteResidents: 'Сохраненные пользователи',
  brand: 'Бренды',
  brandAnsi: 'Бренды (латиницей)',
  invalidUrl: 'недействительная ссылка',
  favouritePartners: 'Сохраненные компании',
  countryCode: 'Код',
  linkedUser: 'Полъзователь с компанией',
  backToCommunity: 'Вернуться в сообщество',
};

export default ru;
