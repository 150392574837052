import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_ACTION_RESULT,
  DEFAULT_LANGUAGE,
  LOCAL_STORAGE_KEYS,
  SLICE_NAMES,
} from '@constants';
import { createCustomSelector } from '@utils';

export const initSlice = createSlice({
  name: SLICE_NAMES.INIT,
  initialState: {
    language: localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE) || DEFAULT_LANGUAGE,
    actionResult: DEFAULT_ACTION_RESULT,
    keywordFilter: '',
    isSearchOpened: false,
  },
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, action.payload);
    },
    setKeywordFilter(state, action) {
      state.keywordFilter = action.payload;
    },
    setSearchState(state, action) {
      state.isSearchOpened = action.payload;
    },
  },
});

export const languageSelector = createCustomSelector((state) => state[SLICE_NAMES.INIT].language);
export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.INIT].actionResult);
export const keywordFilterSelector = createCustomSelector((state) => state[SLICE_NAMES.INIT].keywordFilter);
export const isSearchOpenedSelector = createCustomSelector((state) => state[SLICE_NAMES.INIT].isSearchOpened);

export const { setLanguage, setKeywordFilter, setSearchState } = initSlice.actions;

export default initSlice.reducer;
