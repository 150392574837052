import { PATHS, ROLES } from '@constants';
import { getRole } from '@utils';

export const getRedirectURL = (language, approvedInvestor) => {
  const userRole = getRole();

  if (userRole === ROLES.SEEKER) {
    return PATHS.SEND_MESSAGE(language);
  }
  if (userRole === ROLES.INVESTOR) {
    if (!approvedInvestor) {
      return PATHS.EDIT_INFO(language);
    }

    return PATHS.RESIDENTS(language);
  }

  return PATHS.APPLICATIONS(language);
};
