import AgriculturalIcon from '@assets/img/icons/segments/agricultural.svg';
import MarketingIcon from '@assets/img/icons/segments/marketing.svg';
import ProdIcon from '@assets/img/icons/segments/prod.svg';
import HeavyIndustryIcon from '@assets/img/icons/segments/heavy-industry.svg';
import TravelIcon from '@assets/img/icons/segments/travel.svg';
import ConstructionIcon from '@assets/img/icons/segments/construction.svg';
import ITIcon from '@assets/img/icons/segments/it.svg';
import TradeIcon from '@assets/img/icons/segments/trade.svg';
import HealthIcon from '@assets/img/icons/segments/health.svg';
import OtherIcon from '@assets/img/icons/segments/other.svg';
import CultureIcon from '@assets/img/icons/segments/culture.svg';
import EducationIcon from '@assets/img/icons/segments/education.svg';
import FinanceIcon from '@assets/img/icons/segments/finance.svg';
import LogisticIcon from '@assets/img/icons/segments/logistic.svg';
import ServiceIcon from '@assets/img/icons/segments/service.svg';

export const SEGMENT_ICONS = {
  1: AgriculturalIcon,
  2: ProdIcon,
  3: MarketingIcon,
  4: ITIcon,
  5: TravelIcon,
  6: ConstructionIcon,
  7: ITIcon,
  8: TradeIcon,
  9: HealthIcon,
  10: CultureIcon,
  11: FinanceIcon,
  12: EducationIcon,
  13: LogisticIcon,
  14: ServiceIcon,
  15: OtherIcon,
  42: HeavyIndustryIcon,
};
