import { useSelector } from 'react-redux';
import { languageSelector } from '@redux/init';
import enTranslations from '@assets/i18n/en';
import hyTranslations from '@assets/i18n/hy';
import ruTranslations from '@assets/i18n/ru';

const translations = {
  en: enTranslations,
  hy: hyTranslations,
  ru: ruTranslations,
};

export const useTranslate = (key) => {
  const language = useSelector(languageSelector);
  const dictionary = translations[language];

  if (key.includes('.')) {
    const [parentElement, innerElement] = key.split('.');
    if (dictionary[parentElement] && dictionary[parentElement][innerElement]) {
      return dictionary[parentElement][innerElement];
    }
    return '';
  }
  return dictionary[key];
};
