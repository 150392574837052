import { SLICE_NAMES } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';
import { getRole } from '@utils';

export const searchOpportunities = createAsyncThunk(
  'opportunities/searchOpportunities',
  async ({ offset, saved }, { getState, dispatch }) => {
    const pageSize = getState()[SLICE_NAMES.OPPORTUNITIES].pageSize;
    const filters = getState()[SLICE_NAMES.OPPORTUNITIES].filters;
    const keyword = getState()[SLICE_NAMES.INIT].keywordFilter;
    const role = getRole();
    const cnt = getState()[SLICE_NAMES.OPPORTUNITIES].cnt;
    let current = offset * pageSize || 0;
    const savedQuery = {};
    if (saved) {
      savedQuery.saved = '';
    }

    const cntRes = await request({
      method: 'get', url: `/private/${role}/searchOpportunities/0/0?cnt`, params: { keyword, ...filters, ...savedQuery },
    });
    const newCntRes = await request({
      method: 'get', url: `/private/${role}/searchOpportunities/0/0?cnt&new`, params: { keyword, ...filters, ...savedQuery },
    });

    const checkPagination = Math.ceil(cntRes.data / pageSize) !== Math.ceil(cnt / pageSize);
    if (cntRes.data) {
      if (checkPagination) current = 0;
      const res = await request({
        method: 'get', url: `/private/${role}/searchOpportunities/${current}/${pageSize}`, params: { keyword, ...filters, ...savedQuery },
      });
      return ({
        cnt: cntRes.data,
        newCnt: newCntRes.data,
        data: res.data,
        checkPagination,
      });
    }
    return ({ cnt: 0, newCnt: 0, data: [] });
  },
);

export const getNewOpportunitiesCnt = createAsyncThunk(
  'opportunities/getNewOpportunitiesCnt',
  async () => {
    const role = getRole();

    const cntRes = await request({
      method: 'get', url: `/private/${role}/searchOpportunities/0/0?cnt&new`,
    });

    if (cntRes.data) {
      return ({
        cnt: cntRes.data,
      });
    }
    return ({ cnt: 0 });
  },
);

export const getOpportunity = createAsyncThunk(
  'opportunities/getOpportunity',
  async (id) => {
    const role = getRole();
    const res = await request({
      method: 'get', url: `/private/${role}/getOpportunity/${id}`,
    });
    return res.data;
  },
);

export const approveOpportunity = createAsyncThunk(
  'opportunities/approveOpportunity',
  async (data) => {
    const res = await request({
      method: 'post', url: '/private/admin/approveOpportunity', data,
    });
    return res.data;
  },
);

export const declineOpportunity = createAsyncThunk(
  'opportunities/declineOpportunity',
  async (data) => {
    const res = await request({
      method: 'post', url: '/private/admin/declineOpportunity', data,
    });
    return res.data;
  },
);

export const addOpportunity = createAsyncThunk(
  'opportunities/addOpportunity',
  async (data) => {
    await request({ method: 'post', url: '/private/seeker/addOpportunity', data });
  },
);

export const setOpportunitySaved = createAsyncThunk(
  'opportunities/setOpportunitySaved',
  async ({ data, isOnlySingle = false }, { getState }) => {
    const opportunities = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.OPPORTUNITIES].opportunities));
    const selectedOpportunity = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.OPPORTUNITIES].selectedOpportunity));
    const setBookmarkedResponse = await request({ method: 'post', url: '/private/investor/setOpportunitySaved', data });

    if (setBookmarkedResponse.data.success) {
      if (isOnlySingle) {
        selectedOpportunity.bookmarked = data.saved;
      } else {
        opportunities.forEach((opportunity) => {
          if (opportunity.id === data.opportunity) {
            opportunity.bookmarked = data.saved;
          }
        });
      }
    }

    return { isOnlySingle, opportunities, selectedOpportunity };
  },
);
