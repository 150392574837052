import { searchProjects, setBookmarked, getProject } from '@redux/project/thunks';

const thunksConfig = [
  {
    type: 'searchProjects',
    thunk: searchProjects,
    callbacks: {
      fulfilled: (state, action) => {
        state.cnt = action.payload.cnt;
        state.projects = action.payload.projects;
        state.totalProjects = action.payload.totalProjects;

        if (action.payload.bookmarked || action.payload.checkPagination) {
          state.currentPage = 1;
        }
      },
    },
  },
  {
    type: 'setBookmarked',
    thunk: setBookmarked,
    callbacks: {
      fulfilled: (state, action) => {
        if (action.payload.isOnlySingle) {
          state.currentProject = action.payload.currentProject;
        } else {
          state.projects = action.payload.projects;
        }
      },
    },
  },
  {
    type: 'getProject',
    thunk: getProject,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentProject = action.payload;
      },
    },
  },
];

export default thunksConfig;
