import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Icon = (props) => (
  <i
    className={`icon icon-${props.type} ${props.className || ''}`}
    style={{ fontSize: props.size, ...props.style }}
    onClick={props.onClick ? props.onClick : () => {}}
  />
);

export default Icon;

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};
