import { configureStore } from '@reduxjs/toolkit';

import initReducer from './init';
import broadcastReducer from './broadcast';
import meReducer from './me';
import dictionariesReducer from './dictionaries';
import opportunitiesReducer from './opportunities';
import roomsReducer from './rooms';
import messagesReducer from './messages';
import investorsReducer from './investors';
import projectReducer from './project';
import partnersReducer from './partners';
import { tokenMiddleware } from './middlewares';

export default configureStore({
  reducer: {
    init: initReducer,
    broadcast: broadcastReducer,
    me: meReducer,
    dictionaries: dictionariesReducer,
    opportunities: opportunitiesReducer,
    rooms: roomsReducer,
    messages: messagesReducer,
    investors: investorsReducer,
    project: projectReducer,
    partners: partnersReducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(tokenMiddleware)
  ),
});
