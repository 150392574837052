import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_ACTION_RESULT, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const partnersSlice = createSlice({
  name: SLICE_NAMES.PARTNERS,
  initialState: {
    actionResult: DEFAULT_ACTION_RESULT,
    partners: [],
    currentPartner: {},
    filters: {},
    pageSize: 10,
    currentPage: 1,
    cnt: 0,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    resetPartners(state) {
      state.partners = [];
    },
    resetCurrentPartner(state) {
      state.currentPartner = {};
    },
    setFilter(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].actionResult);
export const partnersSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].partners);
export const currentPartnerSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].currentPartner);
export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].pageSize);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].currentPage);
export const partnersCountSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].cnt);
export const totalPartnersSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].totalPartners);
export const filtersSelector = createCustomSelector((state) => state[SLICE_NAMES.PARTNERS].filters);

export const {
  resetPartners, resetCurrentPartner, setCurrentPage, setFilter,
} = partnersSlice.actions;

export default partnersSlice.reducer;
