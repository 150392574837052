import { SLICE_NAMES } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';
import { setBookmarkProperty } from '@utils';

export const ban = createAsyncThunk(
  'investors/ban',
  async (data) => {
    await request({ method: 'post', url: '/private/shared/ban', data });
  },
);

export const searchOtherInvestors = createAsyncThunk(
  'investors/searchOtherInvestors',
  async ({ offset = 0, bookmarked }, { getState }) => {
    const pageSize = getState().investors.pageSize;
    const filter = getState().investors.filter;
    const keyword = getState().init.keywordFilter;
    let current = offset * pageSize || 0;
    const cnt = getState().investors.cnt;
    const bookmarkedQuery = {};

    if (bookmarked) {
      bookmarkedQuery.bookmarked = '';
    }

    const totalInvestorsRes = await request({
      method: 'get',
      url: '/private/investor/searchOtherInvestors/0/0?cnt',
      params: {
        segments: null,
        ...filter.expertModes,
        ...bookmarkedQuery,
        keyword,
      },
    });

    const cntRes = await request({
      method: 'get', url: '/private/investor/searchOtherInvestors/0/0?cnt', params: { ...filter, keyword, ...bookmarkedQuery },
    });

    let totalInvestors = null;
    if (totalInvestorsRes.data) {
      totalInvestors = totalInvestorsRes.data;
    }

    if (cntRes.data) {
      const checkPagination = Math.ceil(cntRes.data / pageSize) !== Math.ceil(cnt / pageSize);
      if (checkPagination) current = 0;
      const res = await request({
        method: 'get', url: `/private/investor/searchOtherInvestors/${current}/${pageSize}`, params: { ...filter, keyword, ...bookmarkedQuery },
      });
      return ({
        cnt: cntRes.data,
        investors: res.data,
        checkPagination,
        bookmarked,
        totalInvestors,
      });
    }
    return ({ cnt: 0, data: [], bookmarked });
  },
);

export const searchBannedInvestors = createAsyncThunk(
  'investors/searchBannedInvestors',
  async () => {
    const res = await request({
      method: 'get', url: '/private/investor/searchOtherInvestors/0/0?authorBan=1',
    });

    if (res.data) {
      return ({
        data: res.data,
      });
    }

    return { data: [] };
  },
);

export const getInvestor = createAsyncThunk(
  'investors/getInvestor',
  async ({ id }) => {
    const res = await request({
      method: 'get', url: '/private/investor/searchOtherInvestors/0/10', params: { id },
    });

    return ({
      investor: res.data[0],
    });
  },
);

export const getInvestors = createAsyncThunk(
  'investors/getInvestors',
  async ({ id }) => {
    const res = await request({
      method: 'get', url: '/private/investor/searchOtherInvestors/0/10', params: { id },
    });

    return ({
      investors: res.data,
    });
  },
);

export const approveInvestor = createAsyncThunk(
  'investors/approveInvestor',
  async (data) => {
    await request({ method: 'post', url: '/private/admin/approveInvestor', data });
  },
);

export const declineInvestor = createAsyncThunk(
  'investors/declineInvestor',
  async (data) => {
    await request({ method: 'post', url: '/private/admin/declineInvestor', data });
  },
);

export const searchInvestors = createAsyncThunk(
  'investors/searchInvestors',
  async ({ keyword, offset = 0, states }, { getState }) => {
    if (keyword) {
      const length = 100;
      const investorsResponse = await request({ method: 'get', url: `/private/admin/searchInvestors/${offset}/${length}`, params: { keyword } });
      return {
        investors: investorsResponse.data,
      };
    }
    const keywordFilter = getState().init.keywordFilter;
    const pageSize = getState().investors.pageSize;
    let current = offset * pageSize || 0;
    const cnt = getState().investors.cnt;

    const cntRes = await request({
      method: 'get', url: '/private/admin/searchInvestors/0/0?cnt', params: { states, keyword: keywordFilter },
    });

    if (cntRes.data) {
      const checkPagination = Math.ceil(cntRes.data / pageSize) !== Math.ceil(cnt / pageSize);
      if (checkPagination) current = 0;
      const res = await request({
        method: 'get', url: `/private/admin/searchInvestors/${current}/${pageSize}`, params: { states, keyword: keywordFilter },
      });
      return ({ cnt: cntRes.data, investors: res.data });
    }
    return ({ cnt: 0, data: [] });
  },
);

export const setExpert = createAsyncThunk(
  'investors/setExpert',
  async (data) => {
    await request({ method: 'post', url: '/private/admin/setExpert', data });
  },
);

export const setLinked = createAsyncThunk(
  'investors/setLinked',
  async (data) => {
    await request({ method: 'post', url: '/private/admin/setLinked', data });
  },
);

export const setBookmarked = createAsyncThunk(
  'investors/setBookmarked',
  async ({ data, isOnlySingle = false }, { getState }) => {
    const investors = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.INVESTORS].investors));
    const currentInvestor = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.INVESTORS].currentInvestors));
    const setBookmarkedResponse = await request({ method: 'post', url: '/private/shared/bookmark', data });

    if (setBookmarkedResponse.data.success) {
      if (isOnlySingle) {
        currentInvestor.bookmarked = data.bookmark;
      } else {
        investors.forEach((investor) => {
          if (investor.id === data.target) {
            investor.bookmarked = data.bookmark;
          }
        });
      }
    }

    return { investors, currentInvestor };
  },
);
