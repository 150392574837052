export const storeManager = (status, type, actionResult, message) => {
  switch (status) {
    case 'pending':
      actionResult.type = type;
      actionResult.loading = true;
      actionResult.status = false;
      actionResult.message = '';
      break;
    case 'fulfilled':
      actionResult.type = type;
      actionResult.loading = false;
      actionResult.status = true;
      actionResult.message = message;
      break;
    case 'rejected':
      actionResult.type = type;
      actionResult.loading = false;
      actionResult.status = false;
      actionResult.message = message.payload || message.error.message;
      break;
    default:
  }
};
