import { API_VALIDATION_KEYS } from '@constants/apiValidationKeys';

const hy = {
  [API_VALIDATION_KEYS.INVALID_EMAIL_OR_PASSWORD]: 'Անվավեր Էլ․ հասցե/Գաղտնաբառ',
  [API_VALIDATION_KEYS.INVALID_OLD_PASSWORD]: 'Սխալ Հին Գաղտնաբառ',
  [API_VALIDATION_KEYS.CHECK_YOUR_CONNECTION]: 'Ստուգեք Ձեր ինտերնետային կապը',
  [API_VALIDATION_KEYS.RELOAD_AND_TRY_AGAIN]: 'Խնդրում ենք թարմացնել էջը և նորից փորձել',
  [API_VALIDATION_KEYS.AUTHORBANS]: 'Դուք բլոկավորել եք մասնակցին',
  [API_VALIDATION_KEYS.TARGETBANS]: 'Մասնակիցը բլոկավորել է ձեզ',
};

export default hy;
