const hy = {
  signIn: 'Մուտք',
  signUp: 'Գրանցում',
  email: 'Էլ. Հասցե',
  password: 'Գաղտնաբառ',
  forgotPassword: 'Մոռացե՞լ եք գաղտաբառը',
  registerAs: 'Գրանցվել որպես',
  registration: 'Գրանցում',
  resident: 'Անդամ',
  business: 'Բիզնես',
  personalInformation: 'Անձնական տվյալներ',
  signInInfo: 'Մուտքի տվյալներ',
  picture: 'Նկար',
  name: 'Անուն',
  nameArm: 'Անուն (խնդրում ենք լրացնել հայատառ)',
  nameAnsi: 'Name (խնդրում ենք լրացնել լատինատառ)',
  surname: 'Ազգանուն',
  surnameArm: 'Ազգանուն (խնդրում ենք լրացնել հայատառ)',
  surnameAnsi: 'Surname (խնդրում ենք լրացնել լատինատառ)',
  country: 'Երկիր',
  companyCountry: 'Գործունեության երկիր(ներ)',
  save: 'Պահպանել',
  companyName: 'Կազմակերպության անվանում',
  companyNameAnsi: 'Կազմակերպության անվանում (խնդրում ենք լրացնել լատինատառ)',
  companyNameInfo: 'Խնդրում ենք լրացնել առանց իրավական տեսակը նշելու',
  companyRegistrationAddress: 'Ընկերության գլխավոր գրասենյակի գործունեության երկիր',
  companyTIN: 'ՀՎՀՀ',
  phone: 'Կոնտակտային անձի հեռախոսահամար',
  investorPhone: 'Հեռախոսահամար',
  onlyAdminAccess: 'Հասանելի է լինելու միայն հարթակի մոդերատորին',
  segment: 'Գործունեության ոլորտ(ներ)',
  continue: 'Շարունակել',
  repeatPassword: 'Կրկնեք Գաղտնաբառը',
  previous: 'Նախորդը',
  confirm: 'Հաստատել',
  thankYou: 'Շնորհակալություն',
  toWebsite: 'Դեպի կայք',
  passwordRecover: 'Գաղտնաբառի վերականգնում',
  send: 'Ուղարկել',
  newPassword: 'Նոր գաղտնաբառ',
  oldPassword: 'Հին Գաղտնաբառ',
  confirmedProjects: 'Ներդրումային նախագծեր',
  memberProjects: 'Անդամ ընկերություններ',
  message: 'Նամակ',
  messages: 'Նամակներ',
  residents: 'Անդամներ',
  community: 'Համայնք',
  editInformation: 'Անձնական տվյալների խմբագրում',
  changePassword: 'Փոխել գաղտնբառը',
  logOut: 'Ելք',
  favouriteProjects: 'Հավանած նախագծեր',
  favouriteMessages: 'Նշված նամակներ',
  date: 'Ամսաթիվ',
  nameSurname: 'Անուն ազգանուն',
  projectName: 'Նախագծի անվանում',
  title: 'Վերնագիր',
  attachedFile: 'Կից ֆայլ',
  structure: 'Կառուցվածք',
  filterAs: 'Ֆիլտրել ըստ',
  residentStatus: 'Օգտատիրոջ ստատուս',
  upload: 'Վերբեռնել',
  passport: 'Անձնագիր',
  attachDocument: 'Կցել փաստաթուղթը',
  companyRegisterDocument: 'Կազմակերպության գրանցման վկայական',
  companyInfo: 'Կազմակերպության տվյալներ',
  pendingApplication: 'Ձեր հայտը դիտարկվում է',
  messageToResident: 'Նամակ անդամին',
  receiverResidentSegments: 'Ստացող անդամների գործունեության ոլորտ',
  messageTitle: 'Նամակի վերնագիր',
  writeMessage: 'Գրել նամակ',
  attachProgram: 'Կցել ծրագիրը',
  accept: 'Ընդունել',
  requests: 'Հայտադիմումներ',
  notificationToResident: 'Ծանուցում անդամին',
  confirmationStep: 'Հաստատման փուլում',
  decline: 'Չեղարկել',
  declined: 'Չեղարկված',
  modified: 'Թարմացված',
  receiver: 'Հասցեատերեր',
  sendNewNotification: 'Նոր ծանուցում',
  roles: {
    seeker: 'Բիզնես',
    investor: 'Անդամ',
    admin: 'Սուպեր ադմին',
  },
  projectOrCompanyName: 'Նախագծի/Ընկերության անվանում',
  activationSuccess: 'Դուք հաջողությամբ ակտիվացրել եք Ձեր հաշիվը:',
  invalidMailOrPassword: 'Անվավեր Էլ․ հասցե/Գաղտնաբառ',
  invalidMail: 'էլ.հասցեն պետք է լրացվի հետևյալ ֆորմատով՝ exаmple@exаmple.com',
  invalidPassword: 'Սխալ Գաղտնաբառ',
  invalidPhone: 'Անվավեր հեռախոսահամար',
  perPage: 'Ցույց տալ էջում',
  sendTo: 'Ում ուղարկել / ոլորտներ',
  sendToSingle: 'Ում',
  messageSentSuccessfully: 'Նամակը Հաջողությամբ Ուղարկվեց',
  messageSentToChosenSectors: (mail) => `Ձեր նամակն ուղարկված է դիտարկման։ Արդյունքի մասին Ձեզ կտեղեկանցնենք նամակի միջոցով՝ հետևյալ էլ. հասցեին 
  ${mail} 5 աշխատանքային օրվա ընթացքում։`,
  sendNewMessage: 'Ուղարկել նոր նամակ',
  blockedSeekerNotifier: (name) => `Դուք այլևս չեք ստանա նամակներ ${name}-ից։`,
  blockUser: 'Բլոկավորել օգտատիրոջը',
  block: 'Բլոկավորել',
  notifications: 'Ծանուցումներ',
  savedChanges: 'Ձեր փոփոխությունները պահպանվել են',
  notFilled: 'Պարտադիր դաշտ',
  oldAndNewPasswordsMatch: 'Նոր գաղտնաբառը համընկնում է հին գաղնտաբառի հետ',
  notMatchedPassword: 'Գաղտնաբառը չի համապատասխանում',
  passChangedSuccess: 'Գաղտնաբառը հաջողությամբ փոխվեց',
  rejected: 'Մերժված',
  approved: 'Հաստատված',
  unverified: 'Չստուգված',
  declineMessage: 'Չեղարկել Նամակը',
  approveMessage: 'Հաստատել Նամակը',
  deleteResident: 'Ջնջել անդամին',
  deleteResidentNotifier: 'Դուք վստահ եք որ ուզում եք ջնջել անդամի օգտահաշիվը',
  delete: 'Ջնջել',
  documentTitle: 'Բիզնես Հարթակ՝ նոր գործարար կապերու ստեղծելու համար',
  documentDescription: 'Ամբողջ աշխարհի հայազգի գործարարներին միավորող փակ բիզնես հարթակ։ Նեթվորքինգ և համագործակցություն'
    + 'բազմաթիվ ոլորտների գործարարների հետ։ Ստուգված ներդրումային փաթեթներ։',
  attachFile: 'Կցել ֆայլ',
  writeNewMessage: 'Նոր նամակ',
  emailExists: 'Էլ․ հասցեն գոյություն ունի',
  emailDoesNotExist: 'Էլ․ հասցեն չի գտնվել',
  phoneExists: 'Հեռախոսահամարն արդեն գրանված է',
  residentRegisterSuccess: () => 'Գրանցումն ավարտելու համար խնդրում ենք անցնել Ձեր էլ. հասցեին ուղարկված հղումով',
  businessRegisterSuccess: 'Դուք հաջողությամբ գրանցվեցիք հարթակում։',
  passwordRecoverSuccess: (email) => `Գատնաբառի վերականգնման հղումը ուղարկվել է Ձեր էլ փստին <span class='helvetica-85' >${email}</span>`,
  noData: 'Տվյալներ չկան',
  noDataResidentsMsg: 'Այստեղ արտացոլվելու է համայնքի անդամների հետ Ձեր նամակագրությունը։',
  fullName: 'Լրիվ անվանումը',
  field: 'Ոլորտի',
  fields: 'Ոլորտներ',
  byName: 'Անվանման',
  byStatus: 'Ստատուսի',
  byField: 'Ոլորտի',
  state: 'Կարգավիճակ',
  fillNameSurname: 'Լրացնել անուն ազգանունը',
  invalidActivationCode: 'Անվավեր ակտիվացման կոդ',
  aboutProject: 'Նախագծի մասին',
  participant: 'Մասնակից',
  participants: 'Մասնակիցներ',
  searchPlaceholder: 'Որոնել',
  all: 'Բոլորը',
  searchResidentBy: 'որոնել անդամին ըստ',
  expert: 'Փորձագետ',
  notificationsReceived: (investorsCount) => `Հսցեատերեր՝ ${investorsCount}`,
  download: 'Ներբեռնել',
  noApprovedResident: 'Հարթակն ամբողջովին հասանելի կդառնա Ձեր հայտի հաստատումից անմիջապես հետո։ Պատասխանը կստանաք 5 աշխատանքային օրում։',
  reply: 'Պատասխանել',
  fromResident: 'Գործարարներից',
  sender: 'Ուղարկող',
  fromBusiness: 'Այլ հարցումներ',
  companySize: 'Ընկերության չափ',
  companySizeInfo: 'Դուրս գրված հաշիվներով',
  communicationLanguage: 'Հաղորդակցման լեզու(ներ)',
  birth: 'Ծննդյան օր/ամիս/ամսաթիվ',
  unBlock: 'Ապաբլոկավորել',
  unBlockUser: 'Ապաբլոկավորել մասնակցին',
  suspendRoom: 'Ջնջել նամակը',
  unBlockedSeekerNotifier: (name) => `Ցանկանու՞մ եք ապաբլոկավորել ${name}-ին`,
  maxItems: (number) => `Առավելագույնը ${number} տարբերակ.`,
  firstNameInvalidLanguage: 'Անունը պետք է լինի պահանջվող լեզվով',
  lastNameInvalidLanguage: 'Ազգանունը պետք է լինի պահանջվող լեզվով',
  companyInvalidLanguage: 'Կազմակերպության անվանումը պետք է լինի պահանջվող լեզվով',
  brandInvalidLanguage: 'ԱՊՐԱՆՔԱՆԻՇՆԵՐ անվանումը պետք է լինի պահանջվող լեզվով',
  invalidTIN: 'Անվավեր ՀՎՀՀ',
  position: 'Ձեր զբաղեցրած պաշտոնը',
  positionSingle: 'Պաշտոն',
  positionInfo: 'Առավելագույնը 2 տարբերակ/եթե նշված տարբերակներում բացակայում է Ձեր հաստիքի հստակ անվանումը, խնդրում ենք նշել հիերարխիայով ամենամոտը',
  type: 'տեսակ',
  sendMessage: 'Գրել նամակ',
  allProjects: 'Բոլոր նախագծերը',
  investmentObjective: 'Ներդրման նպատակ',
  investmentRound: 'Ներդրումային փուլ',
  investmentRoundInfo: '*Ստարտափների համար (pre-seed, seed, Round A և այլն)',
  forecastIndicators: 'Կանխատեսվող ցուցանիշներ',
  requiredInvestmentVolume: 'Պահանջվող ներդրման չափ',
  profitability: 'Շահութաբերություն/EBITDA',
  profitabilityInfo: `*EBITDA-ն ընկերության գործառնական շահութաբերության ցուցանիշն է\` ելնելով ներդրողին առաջարկվող բաժնեմասից,
  և հաշվարկվում է հետևյալ կերպ. EBITDA = Եկամուտ - ծախսեր (բացառությամբ տոկոսների, հարկերի, մաշվածության և
  ամորտիզացիայի)EBITDA մարժա = (EBITDA / Եկամուտ) * 100`,
  averageRevenue: 'Միջին տարեկան եկամուտ',
  averageRevenueInfo: '*Միջին տարեկան եկամուտը հաշվարկվում է մինչև հետգնման ժամկետ',
  breakEventPoint: 'Հետգնման ժամկետ',
  contactFounder: 'Կապ հիմնադրի հետ',
  projectDescription: 'Նախագծի կարճ նկարագրություն',
  aboutCompany: 'Ընկերության մասին',
  employeeNumber: 'Աշխատողների քանակ',
  cooperationFormats: 'Համագործակցության ձեվաչափ',
  founded: 'Հիմնադրման տարեթիվ',
  website: 'Կայք',
  contactCompany: 'Կապվել ընկերության հետ',
  subSegment: 'Գործունեության ենթաոլորտ(ներ)',
  favouriteResidents: 'Պահպանված անդամներ',
  brand: 'Ապրանքանիշներ',
  brandAnsi: 'Ապրանքանիշներ (խնդրում ենք լրացնել լատինատառ)',
  invalidUrl: 'Սխալ հղում',
  favouritePartners: 'Պահպանված ընկերություններ',
  countryCode: 'Կոդ',
  linkedUser: 'Ընկերությանը կցված օգտատեր',
  backToCommunity: 'Վերադառնալ համայնք',
};

export default hy;
