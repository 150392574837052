import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_STATES, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const investorsSlice = createSlice({
  name: SLICE_NAMES.INVESTORS,
  initialState: { ...DEFAULT_STATES.INVESTORS },

  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    clearPagination(state) {
      state.currentPage = 1;
      state.cnt = 0;
    },
    setFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetInvestors(state) {
      state.investors = [];
    },
    resetMessageInvestor(state) {
      state.messageInvestor = {};
    },
    clearInvestorState: () => DEFAULT_STATES.INVESTORS,
  },
});

export const {
  setCurrentPage,
  setPageSize,
  resetInvestors,
  clearPagination,
  setFilter,
  resetMessageInvestor,
  clearInvestorState,
} = investorsSlice.actions;

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].actionResult);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].currentPage);
export const cntSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].cnt);
export const totalInvestorsSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].totalInvestors);
export const bannedInvestorsSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].bannedInvestors);
export const messageInvestorSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].messageInvestor);
export const currentInvestorsSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].currentInvestors);
export const investorsSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].investors);
export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].pageSize);
export const filterSelector = createCustomSelector((state) => state[SLICE_NAMES.INVESTORS].filter);

export default investorsSlice.reducer;
