import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_STATES, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from '@redux/dictionaries/thunksConfig';

export const dictionariesSlice = createSlice({
  name: SLICE_NAMES.DICTIONARIES,
  initialState: { ...DEFAULT_STATES.DICTIONARIES },
  reducers: {
    resetCountries(state) {
      state.countries = [];
    },
    resetSegments(state) {
      state.segments = [];
    },
    resetLanguages(state) {
      state.languages = [];
    },
    clearDictionariesState: () => DEFAULT_STATES.DICTIONARIES,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const countriesSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].countries);
export const segmentsSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].segments);
export const languagesSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].languages);
export const companyPositionsSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].companyPositions);
export const companySizesSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].companySizes);
export const companyTypesSelector = createCustomSelector((state) => state[SLICE_NAMES.DICTIONARIES].companyTypes);

export const {
  resetCountries,
  resetSegments,
  resetLanguages,
  clearDictionariesState,
} = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
