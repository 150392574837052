import { LOCAL_STORAGE_KEYS } from '@constants';

export const getStorageSignUpValues = () => {
  const STORAGE_SIGN_UP_VALUES = localStorage.getItem(LOCAL_STORAGE_KEYS.SIGN_UP_VALUES);
  return STORAGE_SIGN_UP_VALUES ? JSON.parse(STORAGE_SIGN_UP_VALUES) : {};
};

export const setInputValue = async (keyData, fieldValues) => {
  const localStorageValues = getStorageSignUpValues();

  keyData.forEach((key) => {
    localStorageValues[key] = fieldValues[key];
  });
  localStorage.setItem(LOCAL_STORAGE_KEYS.SIGN_UP_VALUES, JSON.stringify(localStorageValues));
};
