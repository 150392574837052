export const getSegmentId = (segments, segmentOrSubsegmentId) => {
  let mainSegmentId;

  segments.map((segment) => {
    if (segment.id !== segmentOrSubsegmentId) {
      segment.subs.map((subSegment) => {
        if (subSegment.id === segmentOrSubsegmentId) {
          mainSegmentId = segment.id;
        }
      });

      return;
    }

    mainSegmentId = segment.id;
  });

  return mainSegmentId;
};
