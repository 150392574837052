export const specialCharacterRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export const upperCaseCharacterRegExp = /[A-Z]/;
export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegExp = /(?=.*[a-zA-Z])([^"]{8,})$/;
export const numberRegExp = /^[0-9]*$/;
export const urlRegExp = /([A-Za-z0-9]+(\.[A-Za-z0-9]+)+)/;
export const TINRegExp = /^[0-9]{4,}$/;
export const englishRegExp = /^[a-zA-Z @~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i;
export const armenianRegExp = /^[\u0561-\u0587\u0531-\u0556 @~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/;
export const fileExtensionRegExp = /^.*[\\\/]/;
export const firstLetterRegExp = /\b(\w)/g;
// eslint-disable-next-line max-len
export const validUrlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
