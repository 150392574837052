import { createAsyncThunk } from '@reduxjs/toolkit';

import { request } from '@services/Request';
import { setToken } from '@redux/me/index';
import { getRole } from '@utils';

export const register = createAsyncThunk(
  'me/register',
  async ({ role, data }) => {
    await request({ method: 'post', url: `/public/${role}/register`, data });
  },
);

export const login = createAsyncThunk(
  'me/login',
  async (data) => {
    const res = await request({ method: 'post', url: '/public/shared/login', data });
    return res.data;
  },
);

export const activate = createAsyncThunk(
  'me/activate',
  async (data) => {
    const res = await request({ method: 'post', url: '/public/shared/activate', data });
    return res.data;
  },
);

export const getProfile = createAsyncThunk(
  'me/getProfile',
  async () => {
    const userRole = getRole();
    const profileResponse = await request({ method: 'get', url: `/private/${userRole}/getProfile` });
    return profileResponse.data.success;
  },
);

export const emailExists = createAsyncThunk(
  'me/emailExists',
  async (data) => {
    const res = await request({ method: 'post', url: '/public/shared/emailExists', data });
    return res.data;
  },
);

export const phoneExists = createAsyncThunk(
  'me/phoneExists',
  async (data) => {
    const res = await request({ method: 'post', url: '/public/shared/phoneExists', data });
    return res.data;
  },
);

export const recoverPassword = createAsyncThunk(
  'me/recoverPassword',
  async (data) => {
    const recoverPasswordResponse = await request({ method: 'post', url: '/public/shared/recoverPassword', data });
    if (recoverPasswordResponse.data.success) {
      const { id, role, token } = recoverPasswordResponse.data.success;
      return { id, role, token };
    }

    return { id: '', role: '', token: '' };
  },
);

export const sendRecoveryLink = createAsyncThunk(
  'me/sendRecoveryLink',
  async (data) => {
    await request({ method: 'post', url: '/public/shared/sendRecoveryLink', data });
    return { email: data.email };
  },
);

export const resendActivationEmail = createAsyncThunk(
  'me/resendActivationEmail',
  async (data) => {
    await request({ method: 'post', url: '/public/shared/resendActivationEmail', data });
  },
);

export const logout = createAsyncThunk(
  'me/logout',
  async (data) => {
    await request({ method: 'post', url: '/shared/logout', data });
  },
);

export const changePassword = createAsyncThunk(
  'me/changePassword',
  async (data) => {
    const changePasswordResponse = await request({ method: 'post', url: '/private/shared/changePassword', data });
    return { newToken: changePasswordResponse.data.success };
  },
);

export const updateProfile = createAsyncThunk(
  'me/updateProfile',
  async (data) => {
    const role = getRole();
    await request({ method: 'post', url: `private/${role}/updateProfile`, data });
  },
);
