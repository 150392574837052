import moment from 'moment';
import { LOCAL_STORAGE_KEYS, MOMENT_LOCALE_KEYS } from '@constants';
import 'moment/locale/en-gb';
import 'moment/locale/hy-am';
import 'moment/locale/ru';

export const formatDate = (dateString) => {
  const DATE_FORMAT = 'll';
  const APP_LANGUAGE = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
  const MOMENT_LOCALE = MOMENT_LOCALE_KEYS[APP_LANGUAGE];

  if (dateString) {
    moment.locale(MOMENT_LOCALE);
    const date = moment(dateString).format(DATE_FORMAT).split(' ');
    date.pop();
    return date.join(' ');
  }

  return '';
};
