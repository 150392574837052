export const LANGUAGES = {
  EN: 'en',
  HY: 'hy',
  RU: 'ru',
};

export const MOMENT_LOCALE_KEYS = {
  [LANGUAGES.EN]: 'en-gb',
  [LANGUAGES.HY]: 'hy-am',
  [LANGUAGES.RU]: 'ru',
};

export const DEFAULT_LANGUAGE = LANGUAGES.HY;
