import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_ACTION_RESULT, DEFAULT_STATES, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const opportunitiesSlice = createSlice({
  name: SLICE_NAMES.OPPORTUNITIES,
  initialState: { ...DEFAULT_STATES.OPPORTUNITIES },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilter(state, action) {
      state.filters = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    clearSelectedOpportunity(state) {
      state.selectedOpportunity = null;
    },
    clearPagination(state) {
      state.currentPage = 1;
      state.cnt = 0;
    },
    clearActionResult(state) {
      state.actionResult = { ...DEFAULT_ACTION_RESULT };
    },
    clearFilters(state) {
      state.filters = {};
    },
    clearOpportunitiesState: () => DEFAULT_STATES.OPPORTUNITIES,
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].actionResult);
export const opportunityListSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].opportunities);

export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].pageSize);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].currentPage);
export const cntSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].cnt);
export const newCntSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].newCnt);
export const selectedOpportunitySelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].selectedOpportunity);
export const filtersSelector = createCustomSelector((state) => state[SLICE_NAMES.OPPORTUNITIES].filters);

export const {
  setCurrentPage,
  setPageSize,
  clearSelectedOpportunity,
  clearPagination,
  setFilter,
  clearOpportunitiesState,
  clearActionResult,
  clearFilters,
} = opportunitiesSlice.actions;

export default opportunitiesSlice.reducer;
