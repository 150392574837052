import { LOCAL_STORAGE_KEYS } from '@constants';
import enTranslations from '@assets/i18n/api-validations/en';
import hyTranslations from '@assets/i18n/api-validations/hy';
import ruTranslations from '@assets/i18n/api-validations/ru';

const translations = {
  en: enTranslations,
  hy: hyTranslations,
  ru: ruTranslations,
};

export const translateServerMessages = (key) => {
  const language = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
  const neededLanguageTranslations = translations[language];

  return neededLanguageTranslations[key] || key;
};
