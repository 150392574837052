import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getRedirectURL } from '@utils';
import { languageSelector } from '@redux/init';
import { useSelector } from 'react-redux';

const NoAuthRouters = ({ component: Component, token, ...rest }) => {
  const language = useSelector(languageSelector);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Redirect to={getRedirectURL(language)} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default NoAuthRouters;
