import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import {
  ROUTES,
  PATHS,
  getScopeWrapper,
  ROLES,
  LOCAL_STORAGE_KEYS,
  DEFAULT_LANGUAGE,
  LANGUAGES,
} from '@constants';
import { languageSelector, setLanguage } from '@redux/init';
import { profileSelector, tokenSelector } from '@redux/me';
import { getProfile } from '@redux/me/thunks';
import { getNotReadBroadcastsCount } from '@redux/broadcast/thunks';
import { getRole, setDocumentLanguage } from '@utils';
import {
  useMount,
  usePrevious,
  useRouter,
  useTitle,
  useTranslate,
} from '@hooks';

const Routers = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const prevToken = usePrevious(token);
  const { location, replace } = useRouter();
  const language = useSelector(languageSelector);
  const profile = useSelector(profileSelector);
  const translations = {
    documentTitle: useTranslate('documentTitle'),
  };

  useTitle(translations.documentTitle);

  useEffect(() => {
    if (!prevToken && token) {
      dispatch(getProfile());
    }
  }, [token]);

  useEffect(() => {
    const isProfileReady = !!Object.entries(profile).length;

    if (isProfileReady && getRole() === ROLES.INVESTOR) {
      dispatch(getNotReadBroadcastsCount());
      window.unreadNotificationsDispatcher = setInterval(() => {
        dispatch(getNotReadBroadcastsCount());
      }, 60000);
    } else {
      clearInterval(window.unreadNotificationsDispatcher);
    }
  }, [profile]);

  useEffect(() => {
    const lang = window.location.pathname.split('/');
    const localLang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
    if (localLang && lang[1] !== localLang) {
      lang[1] = localLang;
      replace(lang.join('/'));
    }
  }, [window.location.pathname]);

  useMount(() => {
    const val = location.pathname.split('/');
    if (!LANGUAGES[val[1].toLocaleUpperCase()]) {
      val[1] = DEFAULT_LANGUAGE;
      replace(val.join('/'));
    }
    const INITIAL_LANGUAGE = val[1] || localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE) || DEFAULT_LANGUAGE;
    setDocumentLanguage(INITIAL_LANGUAGE);
    dispatch(setLanguage(INITIAL_LANGUAGE));
  });

  return (
    <Switch>
      {
        ROUTES.map(({
          path,
          exact,
          scope,
          component: Component,
        }) => {
          const RouteRenderer = getScopeWrapper(scope);
          return (
            <RouteRenderer
              key={path}
              token={token}
              exact={exact}
              path={path}
              component={Component}
            />
          );
        })
      }
      <Redirect to={PATHS.NOT_FOUND((language))} />
    </Switch>
  );
};

export default Routers;
