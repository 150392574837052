import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const searchMessages = createAsyncThunk(
  'messages/searchMessages',
  async ({ room, offset }, { getState, dispatch }) => {
    const pageSize = 10;
    const filters = getState().messages.filters;
    const keyword = getState().init.keywordFilter;
    const current = offset * pageSize || 0;
    const cntRes = await request({
      method: 'get', url: `/private/shared/searchMessages/${room}/0/0?cnt`, params: { keyword, ...filters },
    });
    const newCntRes = await request({
      method: 'get', url: `/private/shared/searchMessages/${room}/0/0?cnt&new`, params: { keyword, ...filters },
    });

    if (cntRes.data) {
      const res = await request({
        method: 'get', url: `/private/shared/searchMessages/${room}/${current}/${pageSize}?desc`, params: { keyword, ...filters },
      });

      const scrolledMessages = res.data;
      const oldMessages = getState().messages.messages;
      const updatedMessages = offset === 0 ? scrolledMessages : oldMessages.concat(scrolledMessages);

      return ({
        cnt: cntRes.data,
        newCnt: newCntRes.data,
        data: updatedMessages,
      });
    }
    return ({ cnt: 0, newCnt: 0, data: [] });
  },
);

export const addMessage = createAsyncThunk(
  'messages/addMessage',
  async (data) => {
    await request({ method: 'post', url: '/private/shared/addMessage', data });
  },
);
