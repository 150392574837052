import moment from 'moment';

export const formatBirth = (dateString) => {
  const DATE_FORMAT = 'YYYY-MM-DD';

  if (dateString) {
    const date = moment(dateString).format(DATE_FORMAT);

    return date;
  }

  return '';
};
