import { API_VALIDATION_KEYS } from '@constants/apiValidationKeys';

const ru = {
  [API_VALIDATION_KEYS.INVALID_EMAIL_OR_PASSWORD]: 'Неверный адрес электронной почты/пароль',
  [API_VALIDATION_KEYS.INVALID_OLD_PASSWORD]: 'Неверный старый пароль',
  [API_VALIDATION_KEYS.CHECK_YOUR_CONNECTION]: 'Проверьте подключение к интернету',
  [API_VALIDATION_KEYS.RELOAD_AND_TRY_AGAIN]: 'Пожалуйста обновите страницу и попробуйте снова',
  [API_VALIDATION_KEYS.AUTHORBANS]: 'Вы заблокировали данного пользователя.',
  [API_VALIDATION_KEYS.TARGETBANS]: 'Пользователь заблокировал вас.',
};

export default ru;
