import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PATHS } from '@constants';
import { languageSelector } from '@redux/init';
import { useSelector } from 'react-redux';

const AuthRouters = ({ component: Component, token, ...rest }) => {
  const language = useSelector(languageSelector);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return <Redirect to={PATHS.SIGN_IN(language)} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AuthRouters;
