import { LOCAL_STORAGE_KEYS } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getCountries = createAsyncThunk(
  'dictionaries/getCountries',
  async () => {
    const countriesResponse = await request({ method: 'get', url: '/public/shared/getCountries' });
    return countriesResponse.data;
  },
);

export const getSegments = createAsyncThunk(
  'dictionaries/getSegments',
  async () => {
    const route = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) ? 'private' : 'public';
    const segmentsResponse = await request({ method: 'get', url: `/${route}/shared/getSegments` });
    return segmentsResponse.data;
  },
);

export const getLanguages = createAsyncThunk(
  'dictionaries/getLanguages',
  async () => {
    const segmentsResponse = await request({ method: 'get', url: '/public/shared/getLanguages' });
    return segmentsResponse.data;
  },
);

export const getCompanyPositions = createAsyncThunk(
  'dictionaries/getCompanyPositions',
  async () => {
    const segmentsResponse = await request({ method: 'get', url: '/public/shared/getLegalEntityPositions' });
    return segmentsResponse.data;
  },
);

export const getCompanySizes = createAsyncThunk(
  'dictionaries/getCompanySizes',
  async () => {
    const segmentsResponse = await request({ method: 'get', url: '/public/shared/getLegalEntitySizes' });
    return segmentsResponse.data;
  },
);

export const getCompanyTypes = createAsyncThunk(
  'dictionaries/getCompanyTypes',
  async () => {
    const segmentsResponse = await request({ method: 'get', url: '/public/shared/getLegalEntityTypes' });
    return segmentsResponse.data;
  },
);
