import { LOCAL_STORAGE_KEYS } from '@constants';
import {
  getProfile,
  activate,
  emailExists,
  phoneExists,
  register,
  recoverPassword,
  login,
  sendRecoveryLink,
  resendActivationEmail,
  logout,
  changePassword,
  updateProfile,
} from '@redux/me/thunks';

const thunksConfig = [
  {
    type: 'register',
    thunk: register,
    callbacks: {
      fulfilled: () => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SIGN_UP_VALUES);
      },
    },
  },
  {
    type: 'login',
    thunk: login,
    callbacks: {
      fulfilled: (state, action) => {
        state.token = action.payload.success.token;
        localStorage.setItem(LOCAL_STORAGE_KEYS.ROLE, action.payload.success.role);
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, action.payload.success.token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.ID, action.payload.success.id);
      },
    },
  },
  {
    type: 'activate',
    thunk: activate,
  },
  {
    type: 'getProfile',
    thunk: getProfile,
    callbacks: {
      fulfilled: (state, action) => {
        state.profile = action.payload;
      },
    },
  },
  {
    type: 'emailExists',
    thunk: emailExists,
    callbacks: {
      fulfilled: (state, action) => {
        state.emailExists = action.payload.success;
      },
    },
  },
  {
    type: 'phoneExists',
    thunk: phoneExists,
    callbacks: {
      fulfilled: (state, action) => {
        state.phoneExists = action.payload.success;
      },
    },
  },
  {
    type: 'recoverPassword',
    thunk: recoverPassword,
    callbacks: {
      fulfilled: (state, action) => {
        state.token = action.payload.token;
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, action.payload.token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.ROLE, action.payload.role);
        localStorage.setItem(LOCAL_STORAGE_KEYS.ID, action.payload.id);
      },
    },
  },
  {
    type: 'sendRecoveryLink',
    thunk: sendRecoveryLink,
  },
  {
    type: 'resendActivationEmail',
    thunk: resendActivationEmail,
  },
  {
    type: 'logout',
    thunk: logout,
  },
  {
    type: 'changePassword',
    thunk: changePassword,
    callbacks: {
      fulfilled: (state, action) => {
        state.token = action.payload.newToken;
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, action.payload.newToken);
      },
    },
  },
  {
    type: 'updateProfile',
    thunk: updateProfile,
    callbacks: {
      fulfilled: (state, action) => {
        console.log('fulfilled');
      },
    },
  },
];

export default thunksConfig;
