import { message } from 'antd';
import { toFormData, translateServerMessages } from '@utils';
import {
  LOCAL_STORAGE_KEYS,
  SESSION_ERRORS,
  VALIDATION_ERRORS,
  BAN_ERRORS,
} from '@constants';
import Icon from '@components/Icon';
import axios from './axiosApi';

export const request = ({
  url,
  method,
  params,
  data,
}) => {
  const headers = {
    ...(localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) && {
      'X-Token': localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN),
      'X-ID': localStorage.getItem(LOCAL_STORAGE_KEYS.ID),
    }),
    'X-Language': localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE),
  };

  return axios({
    method,
    url,
    data: toFormData(data),
    params,
    headers,
  })
    .catch((error) => {
      const res = error.response;
      const errorMessage = res ? res.data.message : 'Check your connection';
      const messageConfig = {
        className: 'toast-message toast-message_error helvetica-65',
        icon: <Icon type='close-sm' />,
      };

      if (typeof errorMessage === 'string') {
        if (!SESSION_ERRORS.includes(errorMessage) && !VALIDATION_ERRORS.includes(errorMessage)) {
          messageConfig.content = translateServerMessages(errorMessage);
          message.error(messageConfig);
        }
      } else if (Object.keys(errorMessage).includes(BAN_ERRORS[0])) {
        for (const key in errorMessage) {
          const value = errorMessage[key];

          if (value === 1) {
            messageConfig.content = translateServerMessages(key);
            message.error(messageConfig);
          }
        }
      } else {
        messageConfig.content = 'Check your connection';
        message.error(messageConfig);
      }
      throw new Error(errorMessage);
    });
};
