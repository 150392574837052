import { SLICE_NAMES } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const searchRooms = createAsyncThunk(
  'rooms/searchRooms',
  async ({ offset, saved }, { getState, dispatch }) => {
    const pageSize = getState()[SLICE_NAMES.ROOMS].pageSize;
    const filters = getState()[SLICE_NAMES.ROOMS].filters;
    const keyword = getState()[SLICE_NAMES.INIT].keywordFilter;
    const cnt = getState()[SLICE_NAMES.ROOMS].cnt;
    let current = offset * pageSize || 0;
    const savedQuery = {};
    if (saved) {
      savedQuery.saved = '';
    }

    const cntRes = await request({
      method: 'get', url: '/private/shared/searchRooms/0/0?cnt', params: { keyword, ...filters, ...savedQuery },
    });
    const newCntRes = await request({
      method: 'get', url: '/private/shared/searchRooms/0/0?cnt&new', params: { keyword, ...filters, ...savedQuery },
    });

    const checkPagination = Math.ceil(cntRes.data / pageSize) !== Math.ceil(cnt / pageSize);
    if (cntRes.data) {
      if (checkPagination) current = 0;
      const res = await request({
        method: 'get', url: `/private/shared/searchRooms/${current}/${pageSize}`, params: { keyword, ...filters, ...savedQuery },
      });
      return ({
        cnt: cntRes.data,
        newCnt: newCntRes.data,
        data: res.data,
        checkPagination,
      });
    }
    return ({ cnt: 0, newCnt: 0, data: [] });
  },
);

export const getNewRoomsCnt = createAsyncThunk(
  'rooms/getNewRoomsCnt',
  async () => {
    const cntRes = await request({
      method: 'get', url: '/private/shared/searchRooms/0/0?cnt&new',
    });

    if (cntRes.data) {
      return ({
        cnt: cntRes.data,
      });
    }
    return ({ cnt: 0 });
  },
);

export const addRoom = createAsyncThunk(
  'rooms/addRoom',
  async (data) => {
    const res = await request({
      method: 'post', url: '/private/shared/addRoom', data,
    });
    return res.data;
  },
);

export const setRoomSuspended = createAsyncThunk(
  'rooms/setRoomSuspended',
  async (data) => {
    const res = await request({
      method: 'post', url: '/private/shared/setRoomSuspended', data,
    });
    return res.data;
  },
);

export const getRoom = createAsyncThunk(
  'rooms/getRoom',
  async (id) => {
    const res = await request({
      method: 'get', url: `/private/shared/getRoom/${id}`,
    });
    return res.data;
  },
);

export const setRoomSaved = createAsyncThunk(
  'rooms/setRoomSaved',
  async ({ data, isOnlySingle = false }, { getState }) => {
    const rooms = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.ROOMS].rooms));
    const selectedRoom = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.ROOMS].selectedRoom));
    const setBookmarkedResponse = await request({ method: 'post', url: '/private/shared/setRoomSaved', data });

    if (setBookmarkedResponse.data.success) {
      if (isOnlySingle) {
        selectedRoom.saved = data.saved;
      } else {
        rooms.forEach((room) => {
          if (room.id === data.room) {
            room.saved = data.saved;
          }
        });
      }
    }

    return { isOnlySingle, rooms, selectedRoom };
  },
);
