import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_ACTION_RESULT, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const projectSlice = createSlice({
  name: SLICE_NAMES.PROJECT,
  initialState: {
    actionResult: DEFAULT_ACTION_RESULT,
    projects: [],
    currentProject: {},
    filters: {},
    pageSize: 10,
    currentPage: 1,
    cnt: 0,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    resetProjects(state) {
      state.projects = [];
    },
    resetCurrentProject(state) {
      state.currentProject = {};
    },
    setFilter(state, action) {
      state.filters = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].actionResult);
export const projectsSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].projects);
export const currentProjectSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].currentProject);
export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].pageSize);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].currentPage);
export const projectCountSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].cnt);
export const totalProjectsSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].totalProjects);
export const filtersSelector = createCustomSelector((state) => state[SLICE_NAMES.PROJECT].filters);

export const {
  resetProjects, resetCurrentProject, setCurrentPage, setFilter,
} = projectSlice.actions;

export default projectSlice.reducer;
