import { searchMessages, addMessage } from './thunks';

const thunksConfig = [
  {
    type: 'searchMessages',
    thunk: searchMessages,
    callbacks: {
      fulfilled: (state, action) => {
        state.messages = action.payload.data;
        state.cnt = action.payload.cnt;
        state.newCnt = action.payload.newCnt;
        state.currentPage += 1;
      },
    },
  },
  {
    type: 'addMessage',
    thunk: addMessage,
    callbacks: {
      fulfilled: (state, action) => {},
    },
  },
];

export default thunksConfig;
