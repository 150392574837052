import {
  getCountries,
  getSegments,
  getLanguages,
  getCompanyPositions,
  getCompanySizes,
  getCompanyTypes,
} from '@redux/dictionaries/thunks';

const thunksConfig = [
  {
    type: 'getCountries',
    thunk: getCountries,
    callbacks: {
      fulfilled: (state, action) => {
        state.countries = action.payload;
      },
    },
  },
  {
    type: 'getSegments',
    thunk: getSegments,
    callbacks: {
      fulfilled: (state, action) => {
        state.segments = action.payload;
      },
    },
  },
  {
    type: 'getLanguages',
    thunk: getLanguages,
    callbacks: {
      fulfilled: (state, action) => {
        state.languages = action.payload;
      },
    },
  },
  {
    type: 'getCompanyPositions',
    thunk: getCompanyPositions,
    callbacks: {
      fulfilled: (state, action) => {
        state.companyPositions = action.payload;
      },
    },
  },
  {
    type: 'getCompanySizes',
    thunk: getCompanySizes,
    callbacks: {
      fulfilled: (state, action) => {
        state.companySizes = action.payload;
      },
    },
  },
  {
    type: 'getCompanyTypes',
    thunk: getCompanyTypes,
    callbacks: {
      fulfilled: (state, action) => {
        state.companyTypes = action.payload;
      },
    },
  },
];

export default thunksConfig;
