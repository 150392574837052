import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';
import { SLICE_NAMES } from '@constants';
import { setBookmarkProperty } from '@utils';

export const searchPartners = createAsyncThunk(
  'partners/searchPartners',
  async ({ offset, bookmarked }, { getState }) => {
    const pageSize = getState()[SLICE_NAMES.PARTNERS].pageSize;
    let current = offset * pageSize || 0;
    const cnt = getState()[SLICE_NAMES.PARTNERS].cnt;
    const keyword = getState()[SLICE_NAMES.INIT].keywordFilter;
    const filters = getState()[SLICE_NAMES.PARTNERS].filters;
    const bookmarkedQuery = {};
    if (bookmarked) {
      bookmarkedQuery.bookmarked = '';
    }

    const totalPartnersRes = await request({
      method: 'get',
      url: '/private/investor/searchPartners/0/0?cnt',
      params: {
        cnt: '',
        ...bookmarkedQuery,
        keyword,
      },
    });

    let totalPartners = null;
    if (totalPartnersRes.data) {
      totalPartners = totalPartnersRes.data;
    }

    const partnersCountResponse = await request({
      method: 'get',
      url: '/private/investor/searchPartners/0/0',
      params: {
        cnt: '',
        ...bookmarkedQuery,
        keyword,
        ...filters,
      },
    });

    if (partnersCountResponse.data) {
      const checkPagination = Math.ceil(partnersCountResponse.data / pageSize) !== Math.ceil(cnt / pageSize);
      if (checkPagination) current = 0;

      const partnersResponse = await request({
        method: 'get',
        url: `/private/investor/searchPartners/${current}/${pageSize}`,
        params: {
          ...bookmarkedQuery,
          keyword,
          ...filters,
        },
      });

      if (partnersResponse.data && Array.isArray(partnersResponse.data)) {
        return {
          bookmarked,
          cnt: partnersCountResponse.data,
          partners: partnersResponse.data,
          checkPagination,
          totalPartners,
        };
      }
    }

    return {
      cnt: 0,
      partners: [],
      bookmarked,
      totalPartners,
    };
  },
);

export const setBookmarked = createAsyncThunk(
  'partners/setBookmarked',
  async ({ data, isOnlySingle = false }, { getState }) => {
    const partners = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.PARTNERS].partners));
    const currentPartner = JSON.parse(JSON.stringify(getState()[SLICE_NAMES.PARTNERS].currentPartner));
    const setBookmarkedResponse = await request({ method: 'post', url: '/private/investor/setPartnerBookmarked', data });

    if (setBookmarkedResponse.data.success) {
      if (isOnlySingle) {
        currentPartner.bookmarked = data.bookmarked;
      } else {
        partners.forEach((partner) => {
          if (partner.id === data.partner) {
            partner.bookmarked = data.bookmarked;
          }
        });
      }
    }

    return { isOnlySingle, partners, currentPartner };
  },
);

export const getPartner = createAsyncThunk(
  'partners/getPartner',
  async (id) => {
    const getPartnerResponse = await request({ method: 'get', url: `/private/investor/getPartner/${id}` });
    return getPartnerResponse.data.success;
  },
);
