import { searchPartners, setBookmarked, getPartner } from '@redux/partners/thunks';

const thunksConfig = [
  {
    type: 'searchPartners',
    thunk: searchPartners,
    callbacks: {
      fulfilled: (state, action) => {
        state.cnt = action.payload.cnt;
        state.partners = action.payload.partners;
        state.totalPartners = action.payload.totalPartners;

        if (action.payload.bookmarked || action.payload.checkPagination) {
          state.currentPage = 1;
        }
      },
    },
  },
  {
    type: 'setBookmarked',
    thunk: setBookmarked,
    callbacks: {
      fulfilled: (state, action) => {
        if (action.payload.isOnlySingle) {
          state.currentPartner = action.payload.currentPartner;
        } else {
          state.partners = action.payload.partners;
        }
      },
    },
  },
  {
    type: 'getPartner',
    thunk: getPartner,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentPartner = action.payload;
      },
    },
  },
];

export default thunksConfig;
