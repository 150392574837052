import { API_VALIDATION_KEYS } from '@constants/apiValidationKeys';

const en = {
  [API_VALIDATION_KEYS.INVALID_EMAIL_OR_PASSWORD]: 'Invalid email/password',
  [API_VALIDATION_KEYS.INVALID_OLD_PASSWORD]: 'Invalid old password',
  [API_VALIDATION_KEYS.CHECK_YOUR_CONNECTION]: 'Check your connection',
  [API_VALIDATION_KEYS.RELOAD_AND_TRY_AGAIN]: 'Please reload and try again',
  [API_VALIDATION_KEYS.AUTHORBANS]: 'You\'ve blocked the user.',
  [API_VALIDATION_KEYS.TARGETBANS]: 'The user blocked you. ',
};

export default en;
