import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_ACTION_RESULT, DEFAULT_STATES, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const roomsSlice = createSlice({
  name: SLICE_NAMES.ROOMS,
  initialState: { ...DEFAULT_STATES.ROOMS },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilter(state, action) {
      state.filters = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    clearSelectedRoom(state) {
      state.selectedRoom = null;
    },
    clearPagination(state) {
      state.currentPage = 1;
      state.cnt = 0;
    },
    clearActionResult(state) {
      state.actionResult = { ...DEFAULT_ACTION_RESULT };
    },
    clearFilters(state) {
      state.filters = {};
    },
    clearRoomsState: () => DEFAULT_STATES.ROOMS,
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].actionResult);
export const roomListSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].rooms);

export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].pageSize);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].currentPage);
export const cntSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].cnt);
export const newCntSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].newCnt);
export const selectedRoomSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].selectedRoom);
export const filtersSelector = createCustomSelector((state) => state[SLICE_NAMES.ROOMS].filters);

export const {
  setCurrentPage,
  setPageSize,
  clearSelectedRoom,
  clearPagination,
  setFilter,
  clearRoomsState,
  clearActionResult,
  clearFilters,
} = roomsSlice.actions;

export default roomsSlice.reducer;
