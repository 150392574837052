import { LOCAL_STORAGE_KEYS } from './storage';

export const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
export const FILES_URL = `${SERVER_URL}/media/`;
export const RATE_SERVER_URL = process.env.REACT_APP_RATE_API_BASE_URL;
export const SESSION_ERRORS = ['Expired Token', 'Invalid Token', 'Invalid code', 'Invalid Params'];
export const VALIDATION_ERRORS = ['Only Approved Investors', 'Only INVESTOR'];
export const BAN_ERRORS = ['authorBans', 'targetBans'];

export const DEFAULT_ACTION_RESULT = {
  type: '',
  loading: false,
  status: false,
  message: '',
};

export const SLICE_NAMES = {
  INIT: 'init',
  BROADCAST: 'broadcast',
  ME: 'me',
  DICTIONARIES: 'dictionaries',
  ADMIN: 'admin',
  INVESTORS: 'investors',
  PROJECT: 'project',
  PARTNERS: 'partners',
  OPPORTUNITIES: 'opportunities',
  ROOMS: 'rooms',
  MESSAGES: 'messages',
};

export const DEFAULT_STATES = {
  BROADCAST: {
    actionResult: DEFAULT_ACTION_RESULT,
    broadcasts: [],
    currentBroadcast: {},
    pageSize: 12,
    currentPage: 1,
    cnt: 0,
    notReadNotificationsCount: 0,
  },
  ME: {
    actionResult: DEFAULT_ACTION_RESULT,
    signUpInfo: {},
    token: localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN),
    emailExists: false,
    phoneExists: false,
    profile: {},
  },
  INVESTORS: {
    actionResult: DEFAULT_ACTION_RESULT,
    investors: [],
    filter: {
      expertModes: null,
      segments: null,
    },
    pageSize: 12,
    currentPage: 1,
    cnt: 0,
    totalInvestors: 0,
    bannedInvestors: [],
    messageInvestor: {},
    currentInvestors: [],
  },
  OPPORTUNITIES: {
    opportunities: [],
    filters: {},
    selectedOpportunity: null,
    actionResult: DEFAULT_ACTION_RESULT,
    pageSize: 12,
    currentPage: 1,
    cnt: 0,
    newCnt: 0,
  },
  ROOMS: {
    rooms: [],
    filters: {},
    selectedRoom: null,
    actionResult: DEFAULT_ACTION_RESULT,
    pageSize: 12,
    currentPage: 1,
    cnt: 0,
    newCnt: 0,
  },
  MESSAGES: {
    messages: [],
    filters: {},
    actionResult: DEFAULT_ACTION_RESULT,
    pageSize: 12,
    currentPage: 1,
    cnt: 0,
    newCnt: 0,
  },
  DICTIONARIES: {
    actionResult: DEFAULT_ACTION_RESULT,
    countries: [],
    segments: [],
    languages: [],
    companyPositions: [],
    companySizes: [],
    companyTypes: [],
  },
};
