import { languageSelector } from '@redux/init';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useTitle = (title) => {
  const language = useSelector(languageSelector);
  useEffect(() => {
    document.title = title;
  }, [language]);
};
