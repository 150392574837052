import { LOCAL_STORAGE_KEYS } from '@constants/storage';

export const getRole = (profile) => {
  if (profile) {
    return profile.role.toLowerCase();
  }

  const roleInStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.ROLE);
  if (roleInStorage) {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ROLE).toLowerCase();
  }

  return '';
};
