import {
  searchBroadcasts,
  getBroadcast,
  addBroadcast,
  getNotReadBroadcastsCount,
} from '@redux/broadcast/thunks';

const thunksConfig = [
  {
    type: 'searchBroadcasts',
    thunk: searchBroadcasts,
    callbacks: {
      fulfilled: (state, action) => {
        state.cnt = action.payload.cnt;
        if (action.payload.type === 'table') {
          state.broadcasts = action.payload.broadcasts;
        } else {
          state.broadcasts = [...state.broadcasts, ...action.payload.broadcasts];
        }
        if (action.payload.checkPagination) {
          state.currentPage = 1;
        }
      },
    },
  },
  {
    type: 'getBroadcast',
    thunk: getBroadcast,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentBroadcast = action.payload;
      },
    },
  },
  {
    type: 'addBroadcast',
    thunk: addBroadcast,
  },
  {
    type: 'getNotReadBroadcastsCount',
    thunk: getNotReadBroadcastsCount,
    callbacks: {
      fulfilled: (state, action) => {
        state.notReadNotificationsCount = action.payload;
      },
    },
  },
];

export default thunksConfig;
