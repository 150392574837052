export const PATHS = {
  NOT_FOUND: (lang = ':lang') => `/${lang}/`,
  HOME: (lang = ':lang') => `/${lang}/`,
  SIGN_IN: (lang = ':lang') => `/${lang}/sign-in`,
  SIGN_UP: (lang = ':lang') => `/${lang}/sign-up`,
  RESIDENT_STEP_1: (lang = ':lang') => `/${lang}/sign-up/resident/step-1`,
  RESIDENT_STEP_2: (lang = ':lang') => `/${lang}/sign-up/resident/step-2`,
  BUSINESS_STEP_1: (lang = ':lang') => `/${lang}/sign-up/business/step-1`,
  BUSINESS_STEP_2: (lang = ':lang') => `/${lang}/sign-up/business/step-2`,
  BUSINESS_STEP_3: (lang = ':lang') => `/${lang}/sign-up/business/step-3`,
  RESIDENT_MESSAGE: (lang = ':lang') => `/${lang}/resident-message`,
  ACTIVATE: (lang = ':lang') => `/${lang}/activate/:token`,
  PASS_RECOVER: (lang = ':lang') => `/${lang}/recover/:token`,
  BUSINESS_MESSAGE: (lang = ':lang') => `/${lang}/business-message`,
  FIND_ACCOUNT: (lang = ':lang') => `/${lang}/find-account`,
  ACCEPTED_PROJECTS: (lang = ':lang') => `/${lang}/projects`,
  MEMBER_PROJECTS: (lang = ':lang') => `/${lang}/member-projects`,
  PROJECT_INNER: (lang = ':lang', id = ':id') => `/${lang}/projects/${id}`,
  MEMBER_PROJECT_INNER: (lang = ':lang', id = ':id') => `/${lang}/member-projects/${id}`,
  SEND_MESSAGE: (lang = ':lang') => `/${lang}/send-message`,
  SEND_MESSAGE_RESIDENT: (lang = ':lang', id = ':id') => `/${lang}/send-resident-message/${id}`,
  APPLICATIONS: (lang = ':lang') => `/${lang}/applications`,
  MESSAGES_LIST: (lang = ':lang') => `/${lang}/messages`,
  MESSAGE_INNER: (lang = ':lang', id = ':id') => `/${lang}/messages/${id}`,
  ROOM: (lang = ':lang', id = ':id') => `/${lang}/messages/room/${id}`,
  RESIDENTS: (lang = ':lang') => `/${lang}/community`,
  NOTIFY_LIST: (lang = ':lang') => `/${lang}/notifications`,
  NOTIFY_INNER: (lang = ':lang', id = ':id') => `/${lang}/notifications/${id}`,
  SEND_NOTIFY: (lang = ':lang') => `/${lang}/create/notification`,
  CHANGE_PASS: (lang = ':lang') => `/${lang}/change-password`,
  EDIT_INFO: (lang = ':lang') => `/${lang}/edit-info`,
  ADMIN_APPLICATIONS: (lang = ':lang') => `/${lang}/admin-applications`,
};
