import {
  ban,
  searchOtherInvestors,
  searchBannedInvestors,
  getInvestor,
  getInvestors,
  approveInvestor,
  declineInvestor,
  setExpert,
  setLinked,
  searchInvestors,
  setBookmarked,
} from './thunks';

const thunksConfig = [
  {
    type: 'ban',
    thunk: ban,
  },
  {
    type: 'searchOtherInvestors',
    thunk: searchOtherInvestors,
    callbacks: {
      fulfilled: (state, action) => {
        state.investors = action.payload.investors;
        state.cnt = action.payload.cnt;
        if (action.payload.checkPagination) {
          state.currentPage = 1;
        }
        if (action.payload?.totalInvestors) {
          state.totalInvestors = action.payload.totalInvestors;
        }
      },
    },
  },
  {
    type: 'searchBannedInvestors',
    thunk: searchBannedInvestors,
    callbacks: {
      fulfilled: (state, action) => {
        state.bannedInvestors = action.payload.data;
      },
    },
  },
  {
    type: 'getInvestor',
    thunk: getInvestor,
    callbacks: {
      fulfilled: (state, action) => {
        state.messageInvestor = action.payload.investor;
      },
    },
  },
  {
    type: 'getInvestors',
    thunk: getInvestors,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentInvestors = action.payload.investors;
      },
    },
  },
  {
    type: 'approveInvestor',
    thunk: approveInvestor,
  },
  {
    type: 'declineInvestor',
    thunk: declineInvestor,
  },
  {
    type: 'setExpert',
    thunk: setExpert,
  },
  {
    type: 'setLinked',
    thunk: setLinked,
  },
  {
    type: 'searchInvestors',
    thunk: searchInvestors,
    callbacks: {
      fulfilled: (state, action) => {
        state.investors = action.payload.investors;
        state.cnt = action.payload.cnt;
        if (action.payload?.checkPagination) {
          state.currentPage = 1;
        }
      },
    },
  },
  {
    type: 'setBookmarked',
    thunk: setBookmarked,
    callbacks: {
      fulfilled: (state, action) => {
        state.investors = action.payload.investors;
      },
    },
  },
];

export default thunksConfig;
