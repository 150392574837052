import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_ACTION_RESULT, DEFAULT_STATES, SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from './thunksConfig';

export const messagesSlice = createSlice({
  name: SLICE_NAMES.MESSAGES,
  initialState: { ...DEFAULT_STATES.MESSAGES },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setFilter(state, action) {
      state.filters = action.payload;
    },
    clearPagination(state) {
      state.currentPage = 1;
      state.cnt = 0;
    },
    clearActionResult(state) {
      state.actionResult = { ...DEFAULT_ACTION_RESULT };
    },
    clearFilters(state) {
      state.filters = {};
    },
    clearMessagesState: () => DEFAULT_STATES.MESSAGES,
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].actionResult);
export const messagesListSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].messages);

export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].pageSize);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].currentPage);
export const cntSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].cnt);
export const newCntSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].newCnt);
export const filtersSelector = createCustomSelector((state) => state[SLICE_NAMES.MESSAGES].filters);

export const {
  setCurrentPage,
  clearPagination,
  setFilter,
  clearMessagesState,
  clearActionResult,
  clearFilters,
} = messagesSlice.actions;

export default messagesSlice.reducer;
