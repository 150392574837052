import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAMES } from '@constants';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import thunksConfig from '@redux/broadcast/thunksConfig';
import { DEFAULT_STATES } from '@constants/store';

export const broadcastSlice = createSlice({
  name: SLICE_NAMES.BROADCAST,
  initialState: { ...DEFAULT_STATES.BROADCAST },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
  reducers: {
    resetBroadcasts(state) {
      state.broadcasts = [];
      state.currentPage = 1;
      state.cnt = 0;
    },
    resetCurrentBroadcast(state) {
      state.currentBroadcast = {};
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    notReadNotificationsCountSubtractBy(state, action) {
      state.notReadNotificationsCount -= action.payload;
    },
    clearBordcastState: () => DEFAULT_STATES.BROADCAST,
  },
});

export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].actionResult);
export const broadcastsSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].broadcasts);
export const currentBroadcastSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].currentBroadcast);
export const currentPageSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].currentPage);
export const pageSizeSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].pageSize);
export const broadcastCountSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].cnt);
export const notReadNotificationsCountSelector = createCustomSelector((state) => state[SLICE_NAMES.BROADCAST].notReadNotificationsCount);

export const {
  resetBroadcasts,
  resetCurrentBroadcast,
  setCurrentPage,
  setPageSize,
  notReadNotificationsCountSubtractBy,
  clearBordcastState,
} = broadcastSlice.actions;

export default broadcastSlice.reducer;
