import { useMemo } from 'react';
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => ({
    push: history.push,
    replace: history.replace,
    pathname: location.pathname,
    // Merge params and parsed query string into single "query" object
    // so that they can be used interchangeably.
    // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
    query: {
      ...queryString.parse(location.search),
      ...params,
    },
    match,
    location,
    history,
    params,
  }), [params, match, location, history]);
};
