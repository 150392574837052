import { storeManager } from '@utils/storeManager';

export const extraReducersBuilderCase = (builder, config) => {
  config.forEach((item) => {
    builder
      .addCase(item.thunk.pending, (state, action) => {
        storeManager('pending', item.type, state.actionResult);
        if (item?.callbacks?.pending) {
          item.callbacks.pending(state, action);
        }
      })
      .addCase(item.thunk.fulfilled, (state, action) => {
        storeManager('fulfilled', item.type, state.actionResult);
        if (item?.callbacks?.fulfilled) {
          item.callbacks.fulfilled(state, action);
        }
      })
      .addCase(item.thunk.rejected, (state, action) => {
        storeManager('rejected', item.type, state.actionResult, action);
        if (item?.callbacks?.rejected) {
          item.callbacks.rejected(state, action);
        }
      });
  });
};
