import { createSlice } from '@reduxjs/toolkit';
import { createCustomSelector, extraReducersBuilderCase } from '@utils';
import {
  DEFAULT_ACTION_RESULT, DEFAULT_STATES, LOCAL_STORAGE_KEYS, SLICE_NAMES,
} from '@constants';
import thunksConfig from '@redux/me/thunksConfig';

export const meSlice = createSlice({
  name: SLICE_NAMES.ME,
  initialState: { ...DEFAULT_STATES.ME },
  reducers: {
    setSignUpInfo(state, action) {
      state.signUpInfo = action.payload;
    },
    setToken(state, action) {
      if (!action.payload) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ID);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ROLE);
        state.profile = {};
      }
      state.token = action.payload;
    },
    resetActionResult(state) {
      state.actionResult = DEFAULT_ACTION_RESULT;
    },
    clearMeState: () => DEFAULT_STATES.ME,
  },
  extraReducers: (builder) => {
    extraReducersBuilderCase(builder, thunksConfig);
  },
});

export const signUpInfoSelector = createCustomSelector((state) => state[SLICE_NAMES.ME].signUpInfo);
export const tokenSelector = createCustomSelector((state) => state[SLICE_NAMES.ME].token);
export const actionResultSelector = createCustomSelector((state) => state[SLICE_NAMES.ME].actionResult);
export const emailExistsSelector = createCustomSelector((state) => state[SLICE_NAMES.ME].emailExists);
export const phoneExistsSelector = createCustomSelector((state) => state[SLICE_NAMES.ME].phoneExists);
export const profileSelector = createCustomSelector((state) => state[SLICE_NAMES.ME].profile);

export const {
  setSignUpInfo,
  setToken,
  resetActionResult,
  clearMeState,
} = meSlice.actions;

export default meSlice.reducer;
